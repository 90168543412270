import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import applicationLayout from '../../components/layout/Layout';
import BreadCrumbs from '../../components/layout/BreadCrumbs/BreadCrumbs';
import { Drawer, message, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import { pageSize } from './order-list-utils';
import SearchFilters from './SearchFilters';
import OrderDetail from '../../orders/orderDetails/OrderDetail';
import styles from './styles.module.css';
import { CloseOutlined } from '@ant-design/icons';
import moment from 'moment';
import { JobStatus, JobStausColor } from '../../models/JobStatus';
import { getOrderDetails, getOrdersList } from '../../helpers/api';
import { Order } from '../../models/Order';

const dateFormat = 'YYYY-MM-DD';

const formatDateTime = (dateTime: string) =>
  moment(dateTime).format('DD MMM - hh:mm a');

const OrdersList = () => {
  const [ordersList, setOrdersList] = useState<Order[]>([]);
  const [filteredOrders, setFilteredOrders] = useState<Order[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isOrderDetailOpen, setIsOrderDetailOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [rowCount, setRowCount] = useState(pageSize);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<any>();
  const [detailedOrder, setDetailedOrder] = useState<Order | null>();
  const [searchString, setSearchString] = useState('');
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());

  const onTaskIDClick = (record: any) => {
    setSelectedOrder(record);
  };

  const dataTableColumns = [
    {
      title: 'Order ID',
      dataIndex: 'id',
      key: 'id',
      render: (jobID: any, record: Order): any => (
        <a onClick={() => onTaskIDClick(record)}>{jobID}</a>
      ),
    },
    {
      title: 'Customer Name',
      dataIndex: ['user', 'name'],
      key: 'customer_username',
    },
    {
      title: 'Zone',
      dataIndex: ['zone', 'name'],
      key: 'zone_name',
    },
    {
      title: 'Asset Assigned',
      dataIndex: ['asset', 'name'],
      key: 'truck_name',
    },
    {
      title: 'Address',
      dataIndex: ['address', 'address'],
      key: 'job_address',
    },
    {
      title: 'Start Time',
      dataIndex: ['timeslot', 'begins_at'],
      key: 'job_pickup_datetime',
      render: (startTime: string) => formatDateTime(startTime),
    },
    {
      title: 'End Time',
      dataIndex: ['timeslot', 'ends_at'],
      key: 'job_delivery_datetime',
      render: (endTime: string) => formatDateTime(endTime),
    },
    {
      title: 'Total Charge',
      dataIndex: 'total_charge',
      key: 'total_charge',
    },
    {
      title: 'Job Status',
      dataIndex: ['status', 'value'],
      key: 'job_status',
      filters: Object.keys(JobStatus).map((status: string) => {
        return { text: status, value: status };
      }),
      onFilter: (job_status: any, record: Order) =>
        record.status.value.includes(job_status),
      render: (job_status: string) => {
        return (
          <div
            className={styles.taskStatus}
            style={{ backgroundColor: JobStausColor[job_status] || '#333' }}>
            {job_status}
          </div>
        );
      },
    },
  ];

  const fetchOrders = async ({ resetPage = false } = {}) => {
    try {
      setIsLoadingData(true);
      const response = await getOrdersList({
        from: startDate.format(dateFormat),
        to: endDate.format(dateFormat),
      });
      setOrdersList(response?.data?.data ?? []);
      setTotalRecords(response?.data?.meta?.total ?? 0);
      if (resetPage) {
        setPageNumber(1);
      }
    } catch (error) {
      console.log(error);
    } finally {
      window.scrollTo(0, 0);
      setIsLoadingData(false);
    }
  };

  const fetchDetailedOrder = async () => {
    try {
      setIsLoadingData(true);

      const response = await getOrderDetails(selectedOrder.id);
      setDetailedOrder(response?.data?.order);
    } catch (error) {
      message.error(error);
      setSelectedOrder(null);
    } finally {
      setIsLoadingData(false);
    }
  };

  useEffect(() => {
    fetchOrders({ resetPage: true });
  }, [startDate, endDate]);

  useEffect(() => {
    if (selectedOrder) {
      fetchDetailedOrder();
    }
  }, [selectedOrder]);

  useEffect(() => {
    setIsOrderDetailOpen(Boolean(detailedOrder));
  }, [detailedOrder]);

  const handleSearch = () => {
    const filteredOrders = ordersList.filter((order: Order) => {
      const _searchString = searchString.toLowerCase();
      return (
        String(order.id).toLowerCase().includes(_searchString) ||
        order.zone.name.toLowerCase().includes(_searchString) ||
        order.asset.name.toLowerCase().includes(_searchString)
      );
    });

    setFilteredOrders(filteredOrders);
  };

  return (
    <main className={styles.content}>
      <BreadCrumbs
        items={[<Link to="/home">Dashboard</Link>, 'B2C', 'Orders']}
      />
      <div className={styles.headerGrid}>
        <div className={styles.pageTitle}>Orders</div>
        <SearchFilters
          updateDates={(startDate: any, endDate: any) => {
            setStartDate(startDate);
            setEndDate(endDate);
          }}
          startDate={startDate}
          endDate={endDate}
        />
      </div>
      <div className={styles.searchWrapper}>
        <Search
          value={searchString}
          onChange={e => setSearchString(e.target.value)}
          size="large"
          placeholder="Search by Order ID, Zone or Asset name..."
          onSearch={handleSearch}
          enterButton
          suffix={
            <CloseOutlined
              onClick={() => {
                setSearchString('');
                setFilteredOrders([]);
              }}
            />
          }
        />
      </div>

      <Table
        dataSource={!!filteredOrders.length ? filteredOrders : ordersList}
        columns={dataTableColumns}
        rowKey="id"
        rowClassName={styles.ordersTable__row}
        loading={isLoadingData}
        pagination={{
          current: pageNumber,
          onChange: page => setPageNumber(page),
          pageSize: rowCount,
          size: 'small',
          total: totalRecords,
          onShowSizeChange: (page, size) => {
            setPageNumber(page);
            setRowCount(size);
          },
        }}
      />

      {detailedOrder && (
        <Drawer
          placement="left"
          width="auto"
          visible={isOrderDetailOpen}
          closable={true}
          onClose={() => {
            setSelectedOrder(null);
            setDetailedOrder(null);
          }}>
          <OrderDetail
            onTaskUpdateHandler={() => {
              setTimeout(() => {
                fetchOrders();
                fetchDetailedOrder();
              }, 2000);
            }}
            order={detailedOrder}
          />
        </Drawer>
      )}
    </main>
  );
};

export default applicationLayout(OrdersList);
