import * as React from 'react';
import { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import { VehicleDetail } from '../../models/VehicleDetail';
import { FUEL_TYPES } from '../../components/SettingsPanel';
import FuelLabel, { FUELCOLOR, FUELLabelText } from '../fuelLabel/FuelLabel';
import styles from './orderDetail.module.css';
import { OrderItem } from '../../models/Order';

const DEFAULT_FUEL_COLOR = '#000000';

interface OrderDetailVehicleProps {
  orderItems: Array<OrderItem>;
}
function OrderVehiclesDetails(props: OrderDetailVehicleProps) {
  const { orderItems } = props;
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    if (orderItems) {
      const data = orderItems.map((order: OrderItem) => {
        return {
          vehicle_id: order.vehicle.id,
          vehicle: `${order.vehicle.make} ${order.vehicle.model}`,
          plate: `${order.vehicle.plate_code} ${order.vehicle.plate_number}`,
        };
      });
      setData(data);
    }
  }, [orderItems]);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'vehicle_id',
      key: 'vehicle_id',
      ellipsis: true,
    },
    {
      title: 'Vehicle',
      dataIndex: 'vehicle',
      key: 'name',
      ellipsis: true,
    },
    {
      title: 'Plate no',
      dataIndex: 'plate',
      key: 'plate_no',
    },
  ];

  return (
    <ul
      style={{
        width: 'inherit',
        paddingLeft: '0px',
      }}>
      <li>
        <p className={styles.vehicleCount} style={{ padding: '16px 0' }}>
          Total vehicles: {data.length}
        </p>
      </li>
      {data.map((vehicle: any, index: number) => (
        <li
          key={index}
          style={{
            position: 'relative',
            display: 'flex',
            marginBottom: '16px',
          }}>
          <span
            style={{
              background: '#FAFAFA',
              borderRadius: '0.5rem',
              width: '100%',
              padding: '1rem',
            }}>
            <h5
              className={
                styles.vehicleId
              }>{`Vehicle ID:${vehicle['vehicle_id']}`}</h5>
            <Row>
              <Col>
                <span className={styles.vehicleListPlate}>
                  {vehicle['plate']}
                </span>
              </Col>
            </Row>
            <Row>
              <Col>
                <span className={styles.vehicleName}>{vehicle['vehicle']}</span>
              </Col>
            </Row>
          </span>
        </li>
      ))}
    </ul>
  );
}

export default React.memo(OrderVehiclesDetails);
