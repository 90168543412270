import React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import SidePanel from '../../components/layout/sidepanel/SidePanel';
import OrdersList from '../../orders/orders/ordersList';
import styles from './selectedOrdersPanel.module.css';

const SelectedOrdersPanel = ({
  mapSelectedOrder,
  detailedOrder,
  onOrderItemSelected,
  removeSelectedOrder,
  onClearSelectedOrders,
  alerts,
}: any) => {
  return (
    <SidePanel>
      <div className={styles.selectedOrdersList}>
        <div>
          <div className={styles.flexWrapper}>
            <div className="alerts-header">Selected Orders</div>
            <div>
              <Button
                onClick={onClearSelectedOrders}
                shape="circle"
                icon={<CloseOutlined />}
              />
            </div>
          </div>
          <div className={styles.flexWrapper}>
            <div>Total selected: {mapSelectedOrder.length}</div>
            <div>
              <a
                className={styles.clearAllButton}
                onClick={onClearSelectedOrders}>
                Clear all
              </a>
            </div>
          </div>
        </div>
        <div className={styles.orderListContainer}>
          <OrdersList
            detailedOrder={detailedOrder}
            onOrderItemSelected={onOrderItemSelected}
            removeSelectedOrder={removeSelectedOrder}
            jobs={mapSelectedOrder}
            selectedOrder={mapSelectedOrder}
            alerts={alerts}
          />
        </div>
      </div>
    </SidePanel>
  );
};

export default SelectedOrdersPanel;
