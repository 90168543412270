import { LayerProps } from 'react-map-gl';
const OVER_SUPPLIED_ZONE_SCORE = 5;
const UNDER_SUPPLIED_ZONE_SCORE = -5;
const PERFECTLY_SUPPLIED_ZONE_SCORE = 0;
const MAX_OVER_SUPPLIED_OPACITY = 0.8;
const MAX_UNDER_SUPPLIED_OPACITY = 0.8;
const MIN_OPACITY = 0;
const UNDER_SUPPLIED_HEX_CODE = '#FF7068';
const OVER_SUPPLIED_HEX_CODE = '#096DD9';

// import MAP_STYLE from '../map-style-basic-v8.json';

// Make a copy of the map style
export const zonesLayers: LayerProps = {
  id: 'dubai-zones',
  source: 'zones',
  type: 'fill',
  filter: ['has', 'score_color'],
  paint: {
    'fill-color': ['get', 'score_color'],
    'fill-opacity': ['get', 'score_opacity'],
  },
};

export const zonesLayersOutline: LayerProps = {
  id: 'dubai-zones-outline',
  source: 'zones',
  type: 'line',

  paint: {
    'line-width': [
      'case',
      ['boolean', ['feature-state', 'hovered'], false],
      3,
      2,
    ],
    'line-color': [
      'case',
      ['boolean', ['feature-state', 'hovered'], false],
      '#ffffff',
      '#64b2f5',
    ],
  },
};

export const allZonesLayer: LayerProps = {
  id: 'zones',
  source: 'zones',
  type: 'fill',
  paint: {
    'fill-color': 'rgba(0, 0, 0, 0)',
  },
};

export const computeZHColor = (score: number) => {
  if (score > PERFECTLY_SUPPLIED_ZONE_SCORE) {
    return OVER_SUPPLIED_HEX_CODE;
  }
  if (score < PERFECTLY_SUPPLIED_ZONE_SCORE) {
    return UNDER_SUPPLIED_HEX_CODE;
  }
  return 0;
};

export const computeZHScoreOpacity = (score: number) => {
  if (score >= OVER_SUPPLIED_ZONE_SCORE) {
    return MAX_OVER_SUPPLIED_OPACITY;
  }
  if (score <= UNDER_SUPPLIED_ZONE_SCORE) {
    return MAX_UNDER_SUPPLIED_OPACITY;
  }
  if (score > PERFECTLY_SUPPLIED_ZONE_SCORE) {
    return (score / OVER_SUPPLIED_ZONE_SCORE) * MAX_OVER_SUPPLIED_OPACITY;
  }
  if (score < PERFECTLY_SUPPLIED_ZONE_SCORE) {
    return (score / UNDER_SUPPLIED_ZONE_SCORE) * MAX_UNDER_SUPPLIED_OPACITY;
  }
  return MIN_OPACITY;
};

// import MAP_STYLE from '../map-style-basic-v8.json';

// Make a copy of the map style
export const zonesLayersFilled: LayerProps = {
  id: 'dubai-zones-filled',
  source: 'zones',
  type: 'fill',
  paint: {
    'fill-color': {
      property: 'percentile',
      stops: [
        [0, '#3288bd'],
        [1, '#66c2a5'],
        [2, '#abdda4'],
        [3, '#e6f598'],
        [4, '#abdda4'],
        [5, '#66c2a5'],
        [6, '#fdae61'],
        [7, '#f46d43'],
        [8, '#d53e4f'],
        [9, '#3288bd'],
        [10, '#66c2a5'],
        [11, '#abdda4'],
        [12, '#e6f598'],
        [13, '#fdae61'],
        [14, '#f46d43'],
        [15, '#d53e4f'],
      ],
    },
    'fill-opacity': [
      'step',
      ['zoom'],
      ['case', ['boolean', ['feature-state', 'hovered'], false], 1.0, 0.5],
      10,
      0.6,
      14,
      0.4,
      16,
      0.2,
    ],
    'fill-outline-color': [
      'case',
      ['boolean', ['feature-state', 'hovered'], false],
      '#000000',
      '#ffffff',
    ],
  },
};
