import React from 'react';
import 'antd/dist/antd.css';
import { TreeSelect } from 'antd';
import '../css/Dashboard.css';
import { TimeSlot } from '../models/TimeSlot';
const { SHOW_ALL } = TreeSelect;

export class TimeSlotFilter extends React.Component<any, any> {
  state = {
    value: this.props.defaultValue.map((timeSlot: TimeSlot) => timeSlot.id),
  };

  handleTimeSlotChange = (slotSelected: number[]) => {
    this.props.handleTimeSlotSelection(
      slotSelected.map((slot: number) =>
        this.props.slots.find((timeSlot: TimeSlot) => slot === timeSlot.id)
      )
    );
    this.setState({ value: slotSelected.map((slot: number) => slot) });
  };

  render() {
    let treeData: any = this.props.slots.map((slot: TimeSlot) => {
      return { title: slot.displayValue, value: slot.id };
    });
    const tProps: any = {
      treeData,
      size: 'large',
      allowClear: true,
      value: this.state.value,
      onChange: this.handleTimeSlotChange,
      treeCheckable: true,
      dropdownClassName: 'time-filter-item',
      filterTreeNode: true,
      maxTagCount: 1,
      maxTagPlaceholder: `and ${this.state.value.length - 1} more`,
      showCheckedStrategy: SHOW_ALL,
      showArrow: true,
      treeNodeFilterProp: 'title',
      treeDefaultExpandAll: true,
      placeholder: <span className="zone-select">All Time Slots</span>,
      className: 'zone-filter-dropdown',
    };
    return <TreeSelect {...tProps} />;
  }
}
