import React, { FC, useEffect, useState } from 'react';
import { message, Modal, Table } from 'antd';
import styles from './orderDetail.module.css';
import {
  OrderItemVehicle,
  OrderReceipt,
  ReceiptUnitPrice,
} from '../../models/Order';
import { getOrderReceipt } from '../../helpers/api';

interface ReceiptModalProps {
  orderId: number;
  onClose: any;
  visible: boolean;
}

const formatCurrency = (amount: number) =>
  amount ? `CAD $${amount.toFixed(2)}` : '--';

const UnitPrice = (data: ReceiptUnitPrice[]) => (
  <>
    {data.map((unitPrice: ReceiptUnitPrice, index: number) => (
      <div key={index} className={styles.unitPrice__row}>
        {unitPrice.start_range} - {unitPrice.end_range} kW: {unitPrice.price}
        {unitPrice.total_time_in_seconds && (
          <>
            &nbsp;/ {(unitPrice.total_time_in_seconds / 60).toFixed(2)} minutes
          </>
        )}
      </div>
    ))}
  </>
);

const columns = [
  {
    title: 'Vehicle',
    dataIndex: 'vehicle',
    key: 'vehicle',
    render: (vehicle: OrderItemVehicle) => {
      return (
        <>
          {vehicle.make}, {vehicle.model}, {vehicle.plate_code}
          {vehicle.plate_number}
        </>
      );
    },
  },
  {
    title: 'Price/Unit',
    dataIndex: 'unit_prices',
    key: 'price_unit',
    render: (unitPrices: ReceiptUnitPrice[]) => UnitPrice(unitPrices),
  },
  {
    title: 'Actual Price/Unit',
    dataIndex: 'time_per_unit_price',
    key: 'actual_price_unit',
    render: (timeUnitPrices: ReceiptUnitPrice[]) => UnitPrice(timeUnitPrices),
  },
  {
    title: 'Order',
    key: 'order',
    render: () => `80%`,
  },
  {
    title: 'Actual Delivery',
    dataIndex: 'vehicle_soc',
    key: 'vehicle_soc',
    render: (vehicleSoc: number) => `${Math.round(vehicleSoc * 100)}%`,
  },
  {
    title: 'Amount Charged',
    dataIndex: 'order_cost',
    key: 'order_cost',
    render: (orderCost: number) => formatCurrency(orderCost),
  },
];

const Summary = (orderReceipt: OrderReceipt | undefined) => {
  if (!orderReceipt) return;

  return (
    <>
      <Table.Summary.Row className={styles.receiptTable__row}>
        <Table.Summary.Cell index={1} colSpan={5}>
          <strong>Service Fee</strong>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={2}>
          <strong>{formatCurrency(orderReceipt.total_service_fee)}</strong>
        </Table.Summary.Cell>
      </Table.Summary.Row>
      <Table.Summary.Row className={styles.receiptTable__row}>
        <Table.Summary.Cell index={1} colSpan={5}>
          <strong>QST</strong>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={2}>
          <strong>{formatCurrency(orderReceipt.total_qst)}</strong>
        </Table.Summary.Cell>
      </Table.Summary.Row>
      <Table.Summary.Row className={styles.receiptTable__row}>
        <Table.Summary.Cell index={1} colSpan={5}>
          <strong>GST</strong>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={2}>
          <strong>{formatCurrency(orderReceipt.total_gst)}</strong>
        </Table.Summary.Cell>
      </Table.Summary.Row>
      {orderReceipt.promo && (
        <Table.Summary.Row className={styles.receiptTable__row}>
          <Table.Summary.Cell index={1} colSpan={5}>
            <strong>Discount ({orderReceipt.promo.code})</strong>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={2}>
            <strong>{formatCurrency(orderReceipt.discount)}</strong>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      )}
      <Table.Summary.Row className={styles.receiptTable__row}>
        <Table.Summary.Cell index={1} colSpan={5}>
          <strong>Total</strong>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={2}>
          <strong>{formatCurrency(orderReceipt.total_price)}</strong>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </>
  );
};

const ReceiptModal: FC<ReceiptModalProps> = ({ orderId, onClose, visible }) => {
  const [loading, setLoading] = useState(false);
  const [orderReceipt, setOrderReceipt] = useState<OrderReceipt | undefined>();

  useEffect(() => {
    fetchReceipt();
  }, []);

  const fetchReceipt = async () => {
    try {
      setLoading(true);
      const response = await getOrderReceipt(orderId);
      setOrderReceipt(response?.data?.order_receipt);
    } catch (error: any) {
      const errorMessage: string = error?.response?.data?.ErrorMessage;
      message.error(errorMessage || error.message || 'Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      width="70vw"
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.9)' }}
      visible={visible}
      onCancel={onClose}
      title={
        <span className={styles.receiptModal__title}>Service Delivered</span>
      }
      footer={null}
      destroyOnClose={true}
      className={styles.receiptModal}
      bodyStyle={{ padding: 0 }}>
      <div className={styles.receiptModal__content}>
        <Table
          loading={loading}
          rowClassName={styles.receiptTable__row}
          columns={columns}
          dataSource={orderReceipt?.items_receipts || []}
          pagination={false}
          summary={() => Summary(orderReceipt)}
        />
      </div>
    </Modal>
  );
};

export default ReceiptModal;
