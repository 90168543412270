import React, { useEffect, useState, useRef, useContext } from 'react';
import applicationLayout from '../../components/layout/Layout';
import {
  Layout,
  Row,
  Col,
  Tooltip,
  Button,
  Input,
  Tag,
  Drawer,
  message,
  DatePicker,
  Select,
  Divider,
  Checkbox,
} from 'antd';
import moment, { Moment } from 'moment';
import {
  CalendarOutlined,
  CaretDownOutlined,
  CloseOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import io from 'socket.io-client';
import * as authHelper from '../../helpers/auth';
import * as Utils from '../../helpers/utils';
import * as dateTimeHelper from '../../helpers/dateTimeHelper';
import { Fleet, Job, ordersFilter } from '../../models/Task';
import OrdersList from '../../orders/orders/ordersList';
import SidePanel from '../../components/layout/sidepanel/SidePanel';
import MapComponent, { MapRefCaller } from '../../orders/map//Map';
import MAP_STYLE from '../../orders/map/default-map-style';
import {
  computeZHColor,
  computeZHScoreOpacity,
} from '../../orders/layers/zone-layer';
import Zone, { ZonePayload } from '../../models/Zone';
import AssignTruckModal from '../../components/AssignTruckModal';
import { TimeSlot } from '../../models/TimeSlot';
import { Team } from '../../models/Team';

import { fleetStatusColor } from '../../orders/map-utils/pulsing-icon';
import FilterControl from '../../orders/controls/filter-control';
import {
  isActiveJobStatus,
  isValidJobStatus,
  JobStatus,
} from '../../models/JobStatus';
import ZoneFilter from '../../components/ZoneFilter';
import '../../css/Dashboard.css';
import { TimeSlotFilter } from '../../components/TimeSlotFilter';
import { FUEL_TYPES } from '../../components/SettingsPanel';

import OrderDetail from '../../orders/orderDetails/OrderDetail';
import {
  Alert,
  AlertItem,
  AlertType,
  DashboardAlert,
} from '../../models/Alert';
import styles from './home.module.css';
import '../../css/Dashboard.css';
import '../../css/Maps.css';
import FleetDetails from '../../views/FleetDetails';
import useDebounce from '../../helpers/hooks/useDebounce';
import { MIN_INVENTORY_LIMIT } from '../../views/FLeetDetailView';
import { AlertReason } from '../../models/AlertReason';
import SelectedOrdersPanel from './SelectedOrdersPanel';
import {
  MIDNIGHT_ORDERS_END_TIME,
  MIDNIGHT_ORDERS_START_TIME,
  REACT_APP_DEFAULT_MAP_ZOOM,
  ZONE_HEALTH_VIEW_ZOOM,
} from '../../helpers/app-constants';
import { ShiftInfo } from '../../models/Schedule';
import {
  getOrderDetails,
  getOrdersList,
  useGetAssetsList,
  useGetOrdersList,
} from '../../helpers/api';
import { Order } from '../../models/Order';

const { Content } = Layout;
const { Option } = Select;
interface Color {
  [key: string]: string;
}
const FUELCOLOR: Color = {
  SPEC95: '#019966',
  SUP98: '#0382C4',
  DIESEL: '#FEB31C',
  BIODSL: '#36B74B',
};

interface OrderFilters {
  orders: Array<string>;
}
const ewd_server_url = `https://${window.location.hostname}/early_warning_dashboard`;
const DEFAULT_MAPSTYLE = JSON.parse(JSON.stringify(MAP_STYLE));

const timezoneOffset = new Date().getTimezoneOffset() / 60;
const lastTimeSlot = 23;

const getTimeSlotsToCurrent = () => {
  const date = dateTimeHelper.getUTCDate(Math.abs(timezoneOffset));
  const hours = date.getHours();
  // We always wanna include the next slot, unless it's the 11PM-12PM slot, then we stop there
  // const toSlot = hours < lastTimeSlot ? hours + 1 : lastTimeSlot;
  const toSlot = lastTimeSlot;
  const timeslots = Array.from(Array(toSlot + 1).keys());

  return timeslots;
};

const storedFiltersSelection = localStorage.getItem('map-filters-selections');
const savedFiltersSelections: any = storedFiltersSelection
  ? JSON.parse(storedFiltersSelection)
  : {};
const selectedOfflinePilotsFilterValue =
  savedFiltersSelections['offline_pilots'] !== undefined
    ? savedFiltersSelections['offline_pilots']
    : true;

type TabsTypes = 'active_orders' | 'unassigned_orders' | 'alerts';

const getTodaysDate = () => {
  return dateTimeHelper.getTodaysDate();
};

function Home() {
  const todayDate = getTodaysDate();
  const {
    isLoading,
    data: orders = [],
    refetch: refetchOrders,
  } = useGetOrdersList({
    from: todayDate,
    to: todayDate,
  });

  const { data: fleets = [], refetch: refetchAssets } = useGetAssetsList();

  const [isMidnightOrder, setIsMidnightOrder] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(isLoading);
  const [activeTab, setActiveTab] = useState<TabsTypes>('active_orders');
  // const [orders, setOrders] = useState<Array<Order>>([]);
  const [mapSelectedOrder, setMapSelectedOrder] = useState<Array<Order>>([]);
  // const [fleets, setFleets] = useState<Array<Fleet>>([]);
  const [selectedOrder, setSelectedOrder] = useState<Order>();
  const [selectedTruck, setSelectedTruck] = useState<Fleet>();
  const [selectedFeature, setSelectedFeature] = useState<GeoJSON.Feature>();
  const [isDetailViewOpen, setIsDetailViewOpen] = useState<boolean>(false);
  const [zones, setZones] = useState<Array<Zone>>([]);
  const [timeSlots, setTimeSlots] = useState<Array<TimeSlot>>([]);
  const [selectedSlots, setSelectedSlots] = useState<Array<TimeSlot>>(
    Utils.getTimeSlots()
  );

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  const [socketUpdatedOrders, setSocketUpdatedOrders] = useState<Array<Order>>(
    []
  );
  const [socketUpdatedFleets, setSocketUpdatedFleets] = useState<Array<Fleet>>(
    []
  );
  const [fleetOrderMap, setFleetOrderMap] = useState<{
    [id: string]: number[];
  }>({});
  const [fleetShiftMap, setFleetShiftMap] = useState<Map<string, ShiftInfo>>(
    new Map()
  );
  const [filterVisibility, setFilterVisibility] = useState<boolean>(true);
  const [filteredOrders, setFilteredOrders] = useState<{
    active: Array<Order>;
    unassigned: Array<Order>;
  }>({ active: orders, unassigned: [] });
  const [filteredFleets, setFilteredFleets] = useState<Array<Fleet>>([]);
  const [alertInfo, setAlertInfo] = useState<Alert>();
  const [isSearchBarAvailable, setIsSearchBarAvailable] = useState<boolean>(
    false
  );
  const [searchString, setSearchString] = useState<string>('');
  const [selectedFleetID, setSelectedFleetID] = useState(-1);
  const [selectedTruckName, setSelectedTruckName] = useState('');
  const [isTruckDetailViewOpen, setTruckDetailViewOpen] = useState(false);
  const [selectedTruckOnMap, setSelectedTruckOnMap] = useState<
    Fleet | undefined
  >();
  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Moment>(moment());
  // fleetOrderMap: { [id: string]: number[] };
  const [selectedFilters, setSelectedFilters] = useState<OrderFilters>({
    orders: [],
  });
  const [shouldShowOfflineFleets, setShouldShowOfflineFleets] = useState(
    selectedOfflinePilotsFilterValue
  );
  const [selectedTeams, setSelectedTeams] = useState(Utils.getSelectedTeams());
  const [selectedFuelType, setSelectedFuelType] = useState(
    Utils.getSelectedFuelType()
  );
  const mapRef = useRef<MapRefCaller>(null);
  const [zoomLevel, setZoomLevel] = useState(
    mapRef.current?.getZoomLevel() || parseInt(REACT_APP_DEFAULT_MAP_ZOOM)
  );

  const isActiveOrdersView = activeTab === 'active_orders';
  const isUnassignedOrdersView = activeTab === 'unassigned_orders';

  // function onLayerSelected(layer: string, visibility: boolean, type: string) {
  //   switch (type) {
  //     case 'layer':
  //       if (mapRef.current) {
  //         mapRef.current.hideLayers(layer, visibility);
  //       }
  //       break;
  //     case 'data':
  //       if (layer === 'offline_pilots') {
  //         setShouldShowOfflineFleets(visibility);
  //       }
  //       break;
  //     default:
  //       break;
  //   }
  // }
  // function onMapStyleChange(style: string) {
  //   if (mapRef.current) {
  //     mapRef.current.toggleMapStyle(style);
  //   }
  // }

  const updateFleetLocationHandler = (map: any) => {
    // if (map) {
    //   const mapSource = map.current.getSource();
    //   socket.on('update_fleet_location', (data: Fleet) => {
    //     let fleetData = Object.assign({}, mapSource._data);
    //     for (let i = 0; i < fleetData.features.length; i++) {
    //       if (fleetData.features[i].id === data.fleet_id) {
    //         fleetData.features[i].geometry.coordinates = [
    //           data.longitude,
    //           data.latitude,
    //         ];
    //         fleetData.features[i].properties.status = data.status;
    //         fleetData.features[i].properties.available = data.is_available;
    //         fleetData.features[i].properties.fleet_status_color =
    //           fleetStatusColor[data.status][data.is_available];
    //         return;
    //       }
    //     }
    //     mapSource.setData(fleetData);
    //   });
    // }
  };

  // const updateFleet = (updated_fleets: Fleet[]) => {
  //   let fleetData = [...fleets];

  //   updated_fleets.forEach((updated_fleet: Fleet) => {
  //     let fleetIndex = fleets.findIndex(
  //       (fleet: Fleet) => fleet.fleet_id === updated_fleet.fleet_id
  //     );
  //     if (fleetIndex === -1 && !updated_fleet.is_deleted) {
  //       fleetData.push(updated_fleet);
  //     } else {
  //       if (updated_fleet.is_deleted) {
  //         fleetData.splice(fleetIndex, 1);
  //       } else {
  //         if (fleetData[fleetIndex].status != updated_fleet.status) {
  //           fetchShiftTypes([updated_fleet.fleet_id]);
  //         }
  //         fleetData[fleetIndex] = updated_fleet;
  //       }
  //     }
  //   });

  //   setFleets(fleetData);
  //   const filteredFleets = filterFleets(
  //     fleetData,
  //     Utils.getSelectedTeams(),
  //   );
  //   setFilteredFleets(filteredFleets);
  // };

  // const liveDataUpdateHandler = (currentOrders: Array<Job>) => {
  //   socket.on('dashboard', (data: any) => {
  //     let updatedInfo = JSON.parse(data);
  //     if (updatedInfo.hasOwnProperty('fleet') && updatedInfo.fleet === 1) {
  //       let fleetData: Fleet[] =
  //         updatedInfo[Object.keys(updatedInfo)[0]].fleets;
  //       const updatedFleets: Fleet[] = [];
  //       for (let fleetID in fleetData) {
  //         if (fleetData[fleetID].country_id === marketContext.country) {
  //           updatedFleets.push(fleetData[fleetID]);
  //         }
  //       }
  //       setSocketUpdatedFleets(updatedFleets);
  //     } else {
  //       let taskData = updatedInfo[Object.keys(updatedInfo)[0]];
  //       taskData.forEach((task: any) => {
  //         if (task.hasOwnProperty('jobs')) {
  //           setSocketUpdatedOrders(task.jobs);
  //         }
  //       });
  //     }
  //   });
  // };

  // const updateOrders = (jobs: Order[]) => {
  //   let updatedTasks = [...orders];
  //   jobs.forEach((job: Order) => {
  //     let jobIndex = orders.findIndex((task: Order) => task.id === job.id);
  //     if (jobIndex === -1 && isValidJobStatus(job.status)) {
  //       updatedTasks.push(job);
  //     } else {
  //       if (isValidJobStatus(job.status)) {
  //         updatedTasks[jobIndex] = job;
  //       } else {
  //         updatedTasks.splice(jobIndex, 1);
  //       }
  //     }
  //   });
  //   setOrders(updatedTasks);
  // };

  const filterOrders = (orders: Array<Order>) => {
    const selectedStatusFilter = selectedFilters.orders[0];
    const lc_searchString = searchString.trim().toLowerCase();
    const timeFormat = 'H';

    const filteredOrders = orders
      // .filter((order: Order) =>
      //   selectedFleetID >= 0
      //     ? parseInt(order.fleet_id) === selectedFleetID
      //     : true
      // )
      // .filter((order: Order) =>
      //   selectedDate && !isMidnightOrder
      //     ? moment(order.timeslot.begins_at).isSame(
      //         moment(selectedDate),
      //         'day'
      //       )
      //     : true
      // )
      // .filter(
      //   (order: Order) =>
      //     selectedTeams.length === 0 || selectedTeams.includes(order.team_id)
      // )
      // .filter(
      //   (order: Order) =>
      //     selectedFuelType === 0 ||
      //     order.fuel_name_count.hasOwnProperty(
      //       Object.values(FUEL_TYPES)[selectedFuelType - 1] as string
      //     )
      // )
      .filter(
        (order: Order) =>
          selectedSlots.length === 0 ||
          selectedSlots.filter(
            (timeSlot: TimeSlot) => {
              const fromTime = parseInt(
                moment(timeSlot.startTimeEpoch).format(timeFormat)
              );
              const toTime = parseInt(
                moment(timeSlot.endTimeEpoch).format(timeFormat)
              );
              const orderTime = parseInt(
                moment(order.timeslot.begins_at).format(timeFormat)
              );
              return orderTime >= fromTime && orderTime < toTime;
            }
            // parseInt(
            //   order.timeslot.begins_at?.split('T')[1].split(':')[0]
            // ) - timezoneOffset <= slot && slot < Utils.getRelativeDeliveryTime(order)
          ).length
      )
      .filter(ordersFilter(lc_searchString))
      // .filter(order =>
      //   Object.keys(order.fuel_name_count || {}).some(
      //     fuel =>
      //       selectedFuelFilter?.length === 0 ||
      //       selectedFuelFilter?.some(fileteredFuel => {
      //         return Utils.getKeyByValue(FUELLabelText, fileteredFuel) === fuel;
      //       })
      //   )
      // );
      .filter((order: Order) =>
        selectedStatusFilter
          ? order.status.value === selectedStatusFilter
          : true
      );

    return filteredOrders;
  };

  // const filterFleets = (
  //   fleets: Fleet[],
  //   selectedTeams: number[],
  //   selectedFuelType: number,
  //   showOfflineFleets: boolean = shouldShowOfflineFleets
  // ) => {
  //   let fuel_type = '';
  //   if (selectedFuelType > 0) {
  //     fuel_type =
  //       Utils.FuelTypeMap[Object.values(FUEL_TYPES)[selectedFuelType - 1]];
  //   }
  //   return fleets
  //     .filter(
  //       (fleet: any) =>
  //         selectedTeams.length === 0 || selectedTeams.includes(fleet.team_id)
  //     )
  //     .filter(
  //       (fleet: Fleet) =>
  //         selectedFuelType === 0 || fleet.type.localeCompare(fuel_type) === 0
  //     )
  //     .filter((fleet: Fleet) =>
  //       showOfflineFleets
  //         ? true
  //         : fleetStatusColor[fleet.status][fleet.is_available] !== 'offline'
  //     );
  // };

  /**
   * Fetch orders and fleets for the day
   */
  // const fetchOrdersAndFleet = async () => {
  //   try {
  //     setLoading(true);

  //     const date = getTodaysDate();
  //     const response = await getOrdersList({ from: date, to: date });
  //     setOrders(response?.data?.data ?? []);
  //     // const filteredFleets = filterFleets(
  //     //   fleets,
  //     //   Utils.getSelectedTeams(),
  //     //   marketContext.fuelType
  //     // );
  //     // setFilteredFleets(filteredFleets);
  //   } catch (e: any) {
  //     message.error(e.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const fetchShiftTypes = (fleetIDs: number[]) => {
  //   const updatedFleetShiftMap = new Map(fleetShiftMap);
  //   scheduleAPI
  //     .fetchShiftTypes(authHelper.getAccessToken(), fleetIDs)
  //     .then(response => {
  //       response.forEach((shiftInfo, fleetID, map) => {
  //         updatedFleetShiftMap.set(fleetID, shiftInfo);
  //       });
  //       setFleetShiftMap(updatedFleetShiftMap);
  //     })
  //     .catch(error => {
  //       throw error;
  //     });
  // };

  // const updateFleetOrderMap = (orderList: Job[]) => {
  //   const filteredOrders = orderList
  //     .filter((order: Job) =>
  //       selectedFleetID >= 0
  //         ? parseInt(order.fleet_id) === selectedFleetID
  //         : true
  //     )
  //     .filter((order: Job) =>
  //       selectedDate
  //         ? moment(order.job_pickup_datetime).isSame(
  //             moment(selectedDate),
  //             'day'
  //           )
  //         : true
  //     )
  //     .filter(
  //       (order: Job) =>
  //         selectedTeams.length === 0 || selectedTeams.includes(order.team_id)
  //     )
  //     .filter(
  //       (order: Job) =>
  //         selectedSlots.length === 0 ||
  //         selectedSlots.includes(
  //           parseInt(order.job_pickup_datetime.split(' ')[1].split(':')[0])
  //         )
  //     );

  //   let updatedFleetOrderMap = { ...fleetOrderMap };
  //   fleets.forEach((fleet: Fleet) => {
  //     let fleet_orders = filteredOrders
  //       .filter(
  //         (order: any) =>
  //           order.fleet_id === fleet.fleet_id &&
  //           isValidJobStatus(order.job_status)
  //       )
  //       .map((order: any) => {
  //         return order.job_id;
  //       });
  //     updatedFleetOrderMap[fleet.fleet_id] = fleet_orders;
  //   });

  //   setFleetOrderMap(updatedFleetOrderMap);
  // };

  // function fetchZones() {
  //   setZones([]);
  // }

  // function getPilotDetails(fleet_id: number) {
  //   if (fleet_id) {
  //     var myHeaders = new Headers();
  //     var raw = JSON.stringify({
  //       fleet_id: fleet_id,
  //       access_token: authHelper.getAccessToken(),
  //     });

  //     var requestOptions: any = {
  //       method: 'POST',
  //       headers: myHeaders,
  //       body: raw,
  //       redirect: 'follow',
  //     };

  //     fetch(ewd_server_url + '/get-fleet-details', requestOptions)
  //       .then(response => response.text())
  //       .then(result => {
  //         if (result) {
  //           const pilotDetails = {
  //             pilot_name: JSON.parse(result).pilot_name,
  //             pilot_lat: JSON.parse(result).lat,
  //             pilot_long: JSON.parse(result).long,
  //           };
  //           setPilotDetails(pilotDetails);
  //         }
  //       })
  //       .catch(error => {
  //         throw error;
  //       });
  //   }
  // }

  const fetchDetailedOrder = async (selectedOrder: Order) => {
    try {
      setLoading(true);
      const response = await getOrderDetails(selectedOrder.id);
      setSelectedOrder(response?.data?.order);
      setIsDetailViewOpen(true);
    } catch (error) {
      message.error(error);
      setSelectedOrder(undefined);
    } finally {
      setLoading(false);
    }
  };

  function onOrderSelected(order: Order) {
    fetchDetailedOrder(order);
    setAlertInfo(undefined);
    setFilterVisibility(false);
  }

  function removeMapSelectedOrder(jobId: number) {
    const orderIndex = mapSelectedOrder.findIndex(
      (order: Order) => jobId === order.id
    );
    if (orderIndex >= 0) {
      const selectedOrders = [...mapSelectedOrder];
      selectedOrders.splice(orderIndex, 1);
      setMapSelectedOrder(selectedOrders);
    }
  }
  /**
   * Order clicked on Map
   * @param orderId number
   */
  function onOrderClicked(orderId: number) {
    const selectedOrderOnMap = orders.find(
      (order: Order) => order.id === orderId
    );

    const isOrderAlreadySelected =
      mapSelectedOrder.indexOf(selectedOrderOnMap!) === -1;

    if (selectedOrderOnMap && isOrderAlreadySelected) {
      const newMapSelectedOrders = [...mapSelectedOrder, selectedOrderOnMap];
      setMapSelectedOrder(newMapSelectedOrders);
    }
  }
  // function onTruckClicked(truckId: number) {
  //   const selectedTruckOnMap = fleets.find(
  //     (truck: Fleet) => truck.fleet_id === truckId
  //   );
  //   setSelectedTruckOnMap(selectedTruckOnMap);
  //   setTruckDetailViewOpen(true);
  //   if (mapRef.current) {
  //     mapRef.current.adjustViewport({ top: 0, bottom: 0, right: 318, left: 0 });
  //   }
  // }

  // const handleZoneSelection = (teamsSelected: number[]) => {
  //   Utils.setSelectedTeams(teamsSelected);
  //   setSelectedTeams(teamsSelected);
  //   setFilteredFleets(
  //     filterFleets(fleets, teamsSelected, marketContext.fuelType)
  //   );
  //   const filteredAlertList = filterOrderRelatedAlerts(
  //     alerts,
  //     marketContext.country,
  //     teamsSelected,
  //     selectedSlots,
  //     marketContext.fuelType
  //   );
  //   setMapSelectedOrder([]);
  //   setFilteredAlerts(filteredAlertList);
  // };

  const handleTimeSlotSelection = (slotsSelected: TimeSlot[]) => {
    setMapSelectedOrder([]);
    setSelectedSlots(slotsSelected);
  };

  /**
   * Methos to show/hide search bar
   */
  function toggleSearchBar() {
    setIsSearchBarAvailable(!isSearchBarAvailable);
    setSearchString('');
    // searchAlerts('');
  }

  // function showTasksForFleet(fleetId: number, truckName: string) {
  //   setSelectedFleetID(fleetId);
  //   setSelectedTruckName(truckName);
  //   setActiveTab('active_orders');
  // }

  const ordersFilterOptions = () => (
    <>
      {isActiveOrdersView ? (
        <>
          <Option value="" className={styles.fuelTypeFilterLabel}>
            All Orders
          </Option>
          {Object.keys(JobStatus)
            .filter((status: string) => isActiveJobStatus(status))
            .map((status: string) => (
              <Option
                key={status}
                value={status}
                className={styles.fuelTypeFilterLabel}>
                {status}
              </Option>
            ))}
        </>
      ) : (
        <Option value="" className={styles.fuelTypeFilterLabel}>
          All Orders
        </Option>
      )}
    </>
  );

  const refreshViews = () => {
    setTimeout(() => {
      refetchOrders();
      refetchAssets();
    }, 2000);
  };

  // const onTruckSelected = (truck: Fleet) => {
  //   setSelectedTruck(truck);
  // };

  // const handleDatePickerChange = (selectedDate: Moment | null) => {
  //   if (selectedDate) {
  //     setSelectedDate(selectedDate);
  //   }
  //   setIsDatePickerOpen(false);
  // };

  // Load defaults on initial render
  useEffect(() => {
    let slots: TimeSlot[] = Utils.getTimeSlots();

    setTimeSlots(slots);
    // Emit on_boarding event to establish a connection to events room
    // socket.emit('on_boarding', {
    //   access_token: authHelper.getAccessToken(),
    // });
    // liveDataUpdateHandler(orders);

    return () => {
      // if (socket) {
      //   socket.disconnect();
      // }
    };
  }, []);

  useEffect(() => {
    const activeOrders = orders.filter((order: Order) =>
      isActiveJobStatus(order.status.value)
    );
    const unassignedOrders = orders.filter((order: Order) => {
      return order.status.value === 'unassigned';
    });
    setFilteredOrders({
      active: filterOrders(activeOrders),
      unassigned: filterOrders(unassignedOrders),
    });

    // updateFleetOrderMap(orders);

    if (selectedOrder) {
      const updatedSelectedOrder = orders.find(
        (order: Order) => order.id === selectedOrder.id
      );
      if (updatedSelectedOrder) {
        setSelectedOrder(updatedSelectedOrder);
      } else {
        setIsDetailViewOpen(false);
        setSelectedOrder(undefined);
      }
    }
  }, [
    orders,
    selectedTeams,
    selectedSlots,
    searchString,
    selectedFuelType,
    selectedFilters,
  ]);

  // useEffect(() => {
  //   fetchOrdersAndFleet();
  // }, [selectedDate]);

  // Update fleet and orders based on socket events received
  // useEffect(() => {
  //   updateOrders(socketUpdatedOrders);
  // }, [socketUpdatedOrders]);

  // useEffect(() => {
  //   updateFleet(socketUpdatedFleets);
  // }, [socketUpdatedFleets]);

  // useEffect(() => {
  //   const filteredFleets = filterFleets(
  //     fleets,
  //     Utils.getSelectedTeams(),
  //     marketContext.fuelType,
  //     shouldShowOfflineFleets
  //   );
  //   setFilteredFleets(filteredFleets);
  // }, [shouldShowOfflineFleets]);

  useEffect(() => {
    // fetchZones();
    // fetchOrdersAndFleet();
    setIsDetailViewOpen(false);
  }, []);

  // useEffect(() => {
  //   fetchOrdersAndFleet();
  //   // fetchAlerts();
  // }, [debouncedRefreshData]);

  // useEffect(() => {
  //   if (!isTruckDetailViewOpen && selectedFeature) {
  //     flyToCenter(
  //       parseInt(REACT_APP_DEFAULT_MAP_ZOOM!) || 10,
  //       selectedFeature.geometry as GeoJSON.Point
  //     );
  //     if (selectedFeature.properties?.type.localeCompare('fleet') === 0) {
  //       setSelectedFeature(undefined);
  //     }
  //   }
  // }, [isTruckDetailViewOpen]);

  useEffect(() => {
    if (!isDetailViewOpen && selectedFeature) {
      flyToCenter(
        parseInt(REACT_APP_DEFAULT_MAP_ZOOM!) || 10,
        selectedFeature.geometry as GeoJSON.Point
      );
      if (
        selectedFeature.properties?.type.localeCompare('order') === 0 ||
        selectedFeature.properties?.type.localeCompare('alert') === 0
      ) {
        setSelectedFeature(undefined);
      }
      setFilterVisibility(isDetailViewOpen);
    }
    setFilterVisibility(!isDetailViewOpen);
  }, [isDetailViewOpen]);

  useEffect(() => {
    if (selectedTruck && selectedTruck.longitude && selectedTruck.latitude) {
      const feature: GeoJSON.Feature = {
        type: 'Feature',
        id: selectedTruck.fleet_id,
        properties: {
          id: selectedTruck.fleet_id,
          license: selectedTruck.license,
          fleet_name: selectedTruck.fleet_name,
          type: 'fleet',
        },
        geometry: {
          type: 'Point',
          coordinates: [
            parseFloat(selectedTruck.longitude),
            parseFloat(selectedTruck.latitude),
          ],
        },
      };

      setSelectedFeature(feature);
    }
  }, [selectedTruck]);

  // useEffect(() => {
  //   if (alertInfo && alertInfo.job_details.long && alertInfo.job_details.lat) {
  //     const feature: GeoJSON.Feature = {
  //       type: 'Feature',
  //       id: alertInfo.job_details.id,
  //       properties: {
  //         customer: alertInfo.job_details.customer_username,
  //         reason: alertInfo.alert_reason,
  //         status: alertInfo.job_details.status,
  //         type: 'alert',
  //       },
  //       geometry: {
  //         type: 'Point',
  //         coordinates: [
  //           parseFloat(alertInfo.job_details.long),
  //           parseFloat(alertInfo.job_details.lat),
  //         ],
  //       },
  //     };
  //     setSelectedFeature(feature);
  //   }
  // }, [alertInfo]);

  useEffect(() => {
    if (
      selectedOrder &&
      selectedOrder.address.lng &&
      selectedOrder.address.lat
    ) {
      const feature: GeoJSON.Feature = {
        type: 'Feature',
        id: selectedOrder.id,
        properties: {
          customer: selectedOrder.user.name,
          status: selectedOrder.status,
          // fleet: selectedOrder.fleet_name,
          type: 'order',
        },
        geometry: {
          type: 'Point',
          coordinates: [selectedOrder.address.lng, selectedOrder.address.lat],
        },
      };
      setSelectedFeature(feature);
    }
  }, [selectedOrder]);

  const flyToCenter = (zoom?: number, center?: GeoJSON.Point) => {
    if (mapRef.current) {
      mapRef.current.goToViewport(zoom, center);
    }
  };

  return (
    <Layout>
      {mapSelectedOrder.length > 0 && (
        <SelectedOrdersPanel
          mapSelectedOrder={mapSelectedOrder}
          detailedOrder={
            isDetailViewOpen && selectedOrder ? selectedOrder.id : -1
          }
          onOrderItemSelected={(selectedJob: Order) =>
            onOrderSelected(selectedJob)
          }
          removeSelectedOrder={(jobId: number) => {
            setIsDetailViewOpen(false);
            setSelectedOrder(undefined);
            removeMapSelectedOrder(jobId);
          }}
          onClearSelectedOrders={() => setMapSelectedOrder([])}
        />
      )}

      {mapSelectedOrder.length === 0 && (
        <SidePanel>
          <div className={styles.ordersPanelWrapper}>
            <div>
              <div style={{ padding: '0px 24px 8px' }}>
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ padding: '32px 0px 16px 0px' }}>
                  <Col span={12}>
                    <span className="alerts-header">Orders</span>
                  </Col>
                  <Col span={12} style={{ display: 'inherit' }}>
                    <Tooltip title="">
                      <Button
                        className={'list-view-filters'}
                        style={{
                          marginRight: '5px',
                          height: '34px',
                          width: '34px',
                        }}
                        shape="circle"
                        onClick={() => {
                          toggleSearchBar();
                        }}
                        icon={
                          isSearchBarAvailable ? (
                            <CloseOutlined />
                          ) : (
                            <SearchOutlined />
                          )
                        }
                      />
                    </Tooltip>
                  </Col>
                </Row>

                {isSearchBarAvailable && (
                  <Row style={{ marginBottom: '16px' }}>
                    <Input
                      className="search-bar"
                      autoFocus={isSearchBarAvailable}
                      onChange={(e: any) => {
                        setSearchString(e.target.value);
                        // searchAlerts(e.target.value);
                      }}
                      prefix={<SearchOutlined style={{ color: '#757D8A' }} />}
                      size={'large'}
                      placeholder={'Search orders'}
                    />
                  </Row>
                )}
                <Row align="middle">
                  <span
                    onClick={() => {
                      setActiveTab('active_orders');
                      setIsDetailViewOpen(false);
                      setAlertInfo(undefined);
                      setSelectedOrder(undefined);
                    }}
                    className={`fleet-status-header fleet-status-header${
                      isActiveOrdersView ? '-selected' : ''
                    }`}>
                    {`Active (${filteredOrders.active.length})`}
                  </span>
                  {/* <span
                    onClick={() => {
                      setActiveTab('unassigned_orders');
                      setIsDetailViewOpen(false);
                      setSelectedOrder(undefined);
                    }}
                    className={`fleet-status-header fleet-status-header${
                      isUnassignedOrdersView ? '-selected' : ''
                    }`}>
                    {`Unassigned (${filteredOrders.unassigned.length})`}
                  </span> */}
                </Row>
              </div>
              <Row>
                <Col span={24}>
                  <Select
                    size="large"
                    suffixIcon={<CaretDownOutlined />}
                    placeholder="All Orders"
                    optionFilterProp="children"
                    className={styles.filterDropDownButton}
                    // value={selectedFilters.orders[0]}
                    onChange={(val: string) => {
                      setSelectedFilters({
                        ...selectedFilters,
                        orders: [val],
                      });
                    }}
                    dropdownRender={menu => <div>{menu}</div>}>
                    {ordersFilterOptions()}
                  </Select>
                </Col>
              </Row>
            </div>
            <div>
              {/* <Row>
                {selectedFleetID > 0 && (
                  <Tag
                    className={
                      'fleet-status-header fleet-status-header-selected'
                    }
                    style={{ marginLeft: '24px' }}
                    color="#4FC4CD"
                    closable
                    onClose={() => {
                      showTasksForFleet(-1, '');
                    }}>
                    {selectedTruckName}
                  </Tag>
                )}
                {isMidnightOrder && (
                  <Tag
                    className={
                      'fleet-status-header fleet-status-header-selected'
                    }
                    style={{ marginLeft: '24px' }}
                    color="#4FC4CD">
                    {`Orders from ${MIDNIGHT_ORDERS_START_TIME} to ${MIDNIGHT_ORDERS_END_TIME}`}
                  </Tag>
                )}
                {!moment(selectedDate).isSame(moment(), 'day') && (
                  <Tag
                    className={
                      'fleet-status-header fleet-status-header-selected'
                    }
                    style={{ marginLeft: '24px' }}
                    color="#4FC4CD"
                    closable
                    onClose={() => {
                      setSelectedDate(moment());
                    }}>
                    {moment(selectedDate).format('MMM Do YY')}
                  </Tag>
                )}
              </Row> */}
              <Row>
                <Col span={24}>
                  {isActiveOrdersView && (
                    <OrdersList
                      loading={loading}
                      detailedOrder={
                        isDetailViewOpen && selectedOrder
                          ? selectedOrder.id
                          : -1
                      }
                      onOrderItemSelected={(selectedJob: Order) =>
                        onOrderSelected(selectedJob)
                      }
                      jobs={filteredOrders.active}
                      selectedOrder={[]}
                    />
                  )}
                  {isUnassignedOrdersView && (
                    <OrdersList
                      loading={loading}
                      detailedOrder={
                        isDetailViewOpen && selectedOrder
                          ? selectedOrder.id
                          : -1
                      }
                      onOrderItemSelected={(selectedJob: Order) =>
                        onOrderSelected(selectedJob)
                      }
                      jobs={filteredOrders.unassigned}
                      selectedOrder={[]}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </SidePanel>
      )}

      <Content>
        {isDetailViewOpen && (
          <Drawer
            placement="left"
            closable={true}
            width={'auto'}
            closeIcon={
              <Button
                shape="circle"
                style={{ background: 'rgba(117, 125, 138, 0.6)' }}
                icon={
                  <CloseOutlined
                    style={{
                      color: '#ffffff',
                    }}
                  />
                }
              />
            }
            className={'order-details-drawer'}
            onClose={() => {
              setIsDetailViewOpen(false);
              setSelectedOrder(undefined);
            }}
            getContainer={false}
            style={{ position: 'absolute', height: 'calc(100% - 56px)' }}>
            {selectedOrder && (
              <OrderDetail
                onTaskUpdateHandler={() => refreshViews()}
                order={selectedOrder}
                closeDrawer={() => {
                  setIsDetailViewOpen(false);
                  setSelectedOrder(undefined);
                }}
              />
            )}
          </Drawer>
        )}

        {/* <FilterControl
          zoomLevel={zoomLevel}
          flyToCenter={flyToCenter}
          onMapFilterChange={(layer, visibility, type) =>
            onLayerSelected(layer, visibility, type)
          }
          onMapStyleChange={(style: string) => onMapStyleChange(style)}
        /> */}

        <MapComponent
          orders={
            isActiveOrdersView
              ? filteredOrders.active
              : filteredOrders.unassigned
          }
          fleets={fleets}
          selectedFeature={selectedFeature}
          selectedOrder={selectedOrder}
          fleetShiftMap={fleetShiftMap}
          mapSelectedOrders={mapSelectedOrder}
          ref={mapRef}
          zones={zones}
          onOrderClick={(orderID: number) => {
            setIsDetailViewOpen(false);
            setSelectedOrder(undefined);
            onOrderClicked(orderID);
          }}
          // onTruckClick={(truckID: number) => {
          //   onTruckClicked(truckID);
          // }}
          mapStyle={DEFAULT_MAPSTYLE}
          updateFleetLocationHandler={(map: mapboxgl.Map) => {
            updateFleetLocationHandler(map);
          }}
          updateZoomLevel={setZoomLevel}>
          <div className={styles.filtersWrapper}>
            {filterVisibility && (
              <TimeSlotFilter
                defaultValue={[]}
                slots={timeSlots}
                handleTimeSlotSelection={(slotsSelected: TimeSlot[]) =>
                  handleTimeSlotSelection(slotsSelected)
                }
              />
            )}
          </div>
        </MapComponent>
      </Content>
    </Layout>
  );
}

export default applicationLayout(Home);
