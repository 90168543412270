import React from 'react';
import { Typography } from 'antd';
import { JobStatus, JobStausColor } from '../../models/JobStatus';
import * as dateTimeHelper from '../../helpers/dateTimeHelper';
import styles from './orderItem.module.css';
import { FuelLabelTag } from '../fuelLabel/FuelLabel';
import { Job } from '../../models/Task';
import { MinusCircleOutlined } from '@ant-design/icons';
import cx from 'classnames';
import { Order } from '../../models/Order';

const { Text, Paragraph } = Typography;
interface OrderDetailProps {
  job: Order;
  onOrderSelected: (job: Order) => void;
  removeSelectedOrder?: (jobId: number) => void;
  selectedOrder: Order[] | undefined;
  detailedOrder: number;
  shouldAlert?: boolean;
}

function OrderItem(props: OrderDetailProps) {
  const {
    job,
    onOrderSelected,
    selectedOrder,
    detailedOrder,
    removeSelectedOrder,
    shouldAlert = false,
  } = props;
  function isOrderSelected(): boolean {
    return selectedOrder
      ? selectedOrder.some(order => order.id === job.id)
      : false;
  }

  return (
    <div
      style={{ background: detailedOrder === job.id ? '#ECECEC' : '#fff' }}
      className={cx(styles.itemContainer, {
        [styles.itemContainer__alerted]: shouldAlert,
      })}
      onClick={event => {
        event.stopPropagation();
        onOrderSelected(job);
      }}>
      <div className={styles.removeSelector}>
        {isOrderSelected() && (
          <MinusCircleOutlined
            onClick={(e: any) => {
              e.stopPropagation();
              removeSelectedOrder && removeSelectedOrder(job.id);
            }}
          />
        )}
      </div>
      <div className={styles.slot}>
        {`
          ${dateTimeHelper.convertDateTimeToCurrentZone(
            job.timeslot.begins_at,
            ''
          )} 
          - ${dateTimeHelper.convertDateTimeToCurrentZone(
            job.timeslot.ends_at,
            ''
          )}
        `}
      </div>
      <span
        className={styles.status}
        style={{
          background: JobStausColor[JobStatus[job.status.value]],
        }}>
        {JobStatus[job.status.value]}
      </span>

      <div className={styles.name}>{job.user.name}</div>

      <div
        className={styles.itemFooter}
        onClick={(event: any) => {
          event.preventDefault();
          event.stopPropagation();
        }}>
        <Paragraph
          copyable={{
            icon: [<span className={styles.orderId}>{job.id}</span>],
            text: `${job.id}`,
            tooltips: false,
          }}
          className={styles.orderIdLabel}>
          <span>Order# </span>
        </Paragraph>
        <Paragraph
          copyable={{
            icon: [<span className={styles.orderId}>{job.asset.name}</span>],
            text: `${job.asset.name}`,
            tooltips: false,
          }}
          className={styles.truckIdLabel}>
          <span> &nbsp;| Truck# </span>
        </Paragraph>
      </div>
    </div>
  );
}

export default React.memo(OrderItem);
