const mapStyle = {
  version: 8,
  name: 'Basic',
  metadata: {
    'mapbox:autocomposite': true,
  },
  sprite: 'mapbox://sprites/mapbox/basic-v8',
  glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
  sources: {},
  layers: [],
};

export default mapStyle;
