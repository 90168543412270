import { LayerProps } from 'react-map-gl';

//Unclusters layer
export const alertsLayer: LayerProps = {
  id: 'alerts',
  type: 'symbol',
  source: 'alerts',
  layout: {
    'icon-image': 'accepted-icon',
    'icon-size': 2,
    'icon-allow-overlap': true,
    'text-allow-overlap': true,
  },
  paint: {
    'icon-color': [
      'match',
      ['get', 'status'],
      7,
      '#e36df7',
      4,
      '#3F51B5',
      1,
      '#0092f7',
      6,
      '#999999',
      '#BA68C8',
    ],
    'icon-halo-width': 2,
    'icon-halo-color': '#ffffff',
  },
};
