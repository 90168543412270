import Cookies from 'universal-cookie';
import { ProductType } from '../domains/operations/supply/trucks/Model/Truck';
const cookies = new Cookies();

export function setLoginData(result: any) {
  let accessCredentials: any = {};
  //result = JSON.parse(result);
  if (result.hasOwnProperty('access_token')) {
    accessCredentials['access_token'] = result.access_token;
  }
  if (result.hasOwnProperty('user_id')) {
    accessCredentials['user_id'] = result.user_id;
  }
  if (result.hasOwnProperty('username')) {
    accessCredentials['username'] = result.username;
  }
  localStorage.setItem('accessCredentials', JSON.stringify(accessCredentials));
  if (result.hasOwnProperty('teams')) {
    localStorage.setItem('teams', JSON.stringify(result.teams));
  }
  if (result.hasOwnProperty('countries')) {
    localStorage.setItem('countries', JSON.stringify(result.countries));
  }
}

export function getLoginData(key: string) {
  let data = localStorage.getItem(key);
  return data != null ? JSON.parse(data) : [];
}

export function getAccessToken() {
  return cookies.get('token');
}

export function getUserID() {
  let authData = getLoginData('accessCredentials');
  return authData.user_id;
}

export function getTeams() {
  return getLoginData('teams');
}

export function getTimezone(country_id: number) {
  let timezone = 'Asia/Dubai';
  const countries = getLoginData('countries');
  if (countries.length) {
    timezone = countries.find(
      (country: any) => country.country_id == country_id
    ).timezone_string;
  }
  return timezone;
}

/**
 * Checks if the user is logged in via Azure OIDC
 * @returns boolean
 */
export const isSessionCookieAvailable = () => {
  let cookie = cookies.get('isAzureAuthorized');
  return !!cookie;
};
/**
 * To check if azure ad integration is available for the env
 * @returns boolean
 */
export const isAzureAvailable = () => false;

/**
 * Validates the build for dev mode
 * @returns boolean
 */
export const isDevMode = () => {
  return process.env.NODE_ENV == 'development';
};

export const isProdEnv = () => {
  if (typeof window === undefined) {
    return process.env.NODE_ENV == 'production';
  } else {
    return window.location.host === 'ev-dashboard-client.ca.prod.cafu.app';
  }
};

export const isLoggedIn = () => {
  let accessToken = getAccessToken();
  const isLogged = accessToken && accessToken !== 'undefined';
  return isAzureAvailable() ? isLogged && isSessionCookieAvailable() : isLogged;
};

export const setProductTypes = (fuel_map: Array<ProductType>) => {
  localStorage.setItem('fuel_map', JSON.stringify(fuel_map));
};
export const getProductTypes = () => {
  const fuel_map = localStorage.getItem('fuel_map');
  return JSON.parse(fuel_map ? fuel_map : '');
};

export const clearAccessCookies = () => {
  cookies.remove('token', { path: '/' });
  cookies.remove('refresh_token', { path: '/' });
};

export const updateAccessCookies = ({ token, refreshToken }: any) => {
  cookies.set('token', token, { path: '/' });
  cookies.set('refresh_token', refreshToken, { path: '/' });
};
