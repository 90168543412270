export function addPulseAnimation(
  map: mapboxgl.Map,
  innerColor: string,
  outerColor: string
) {
  let size = 125;
  let context: CanvasRenderingContext2D | null = null;

  var pulsingDot = {
    width: size,
    height: size,
    data: new Uint8ClampedArray(size * size * 4),

    // get rendering context for the map canvas when layer is added to the map
    onAdd: function () {
      var canvas = document.createElement('canvas');
      canvas.width = this.width;
      canvas.height = this.height;
      context = canvas.getContext('2d');
    },

    // called once before every frame where the icon will be used
    render: function () {
      var duration = 3000;
      var t = (performance.now() % duration) / duration;

      var radius = (size / 2) * 0.3;
      var outerRadius = (size / 3) * 0.7 * t + radius;
      if (context) {
        // draw outer circle
        context.clearRect(0, 0, this.width, this.height);
        context.beginPath();
        context.arc(
          this.width / 2,
          this.height / 2,
          outerRadius,
          0,
          Math.PI * 2
        );
        context.fillStyle = eval('`' + innerColor + '`');
        context.fill();

        // draw inner circle
        context.beginPath();
        context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
        context.fillStyle = outerColor;
        context.strokeStyle = 'white';
        context.lineWidth = 1 + 4 * (1 - t);
        context.fill();
        context.stroke();

        // update this image's data with data from the canvas
        this.data = context.getImageData(0, 0, this.width, this.height).data;

        // continuously repaint the map, resulting in the smooth animation of the dot
        map.triggerRepaint();
      }

      // return `true` to let the map know that the image was updated
      return true;
    },
  };

  return pulsingDot;
}

export function addFleetMarker(outerColor: string) {
  let size = 125;
  let context: CanvasRenderingContext2D | null = null;

  var fleetDot = {
    width: size,
    height: size,
    data: new Uint8ClampedArray(size * size * 4),

    // get rendering context for the map canvas when layer is added to the map
    onAdd: function () {
      var canvas = document.createElement('canvas');
      canvas.width = this.width;
      canvas.height = this.height;
      context = canvas.getContext('2d');
    },

    // called once before every frame where the icon will be used
    render: function () {
      var duration = 3000;
      var t = (performance.now() % duration) / duration;

      var radius = (size / 2) * 0.3;
      if (context) {
        //draw truck shape, rhombus
        context.beginPath();
        context.moveTo(50, 25);
        context.lineTo(70, 45);
        context.lineTo(50, 65);
        context.lineTo(30, 45);
        context.lineTo(50, 25);
        context.fillStyle = outerColor;
        context.strokeStyle = 'white';
        context.lineWidth = 1 + 4 * (1 - t);
        context.fill();
        context.stroke();

        // update this image's data with data from the canvas
        this.data = context.getImageData(0, 0, this.width, this.height).data;
      }

      // return `true` to let the map know that the image was updated
      return true;
    },
  };

  return fleetDot;
}

interface FleetColorStatus {
  [key: number]: { [key: number]: string };
}
// exports.fleetStatusColor = {
//   0: {0: '#999999', 1: '#63AE0C'},
//   1: {0: '#999999', 1: '#2196F3'},
//   2: {0: '#999999', 1: '#2196F3'}
// };

// exports.fleetStatus = {
//   OFFLINE : 2,
//   BUSY    : 1,
//   ONLINE  : 0,
//   INACTIVE: 3
// };
export const fleetStatusColor: FleetColorStatus = {
  0: { 0: 'offline', 1: 'idle' },
  1: { 0: 'offline', 1: 'available' },
  2: { 0: 'offline', 1: 'available' },
  3: { 0: 'offline', 1: 'available' },
};
