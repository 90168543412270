import React, { useEffect } from 'react';
import { useState } from 'react';
import { Layout, Button, Spin, Tabs, Row, Col, message } from 'antd';
import moment from 'moment';
import { ChangeJobStatusModal } from '../../components/ChangeJobStatusModal';
import ChangeJobScheduleModal from '../../components/ChangeJobScheduleModal/ChangeJobScheduleModal';
import { Job, TimelineEvent } from '../../models/Task';
import { AssignedPilotInfo } from '../../models/Fleet';
import styles from './orderDetail.module.css';
import { isActiveJobStatus, JobStatus } from '../../models/JobStatus';
import * as dateTimeHelper from '../../helpers/dateTimeHelper';
import AssignTruckModal from '../../components/AssignTruckModal';
import * as Utils from '../../helpers/utils';
import * as authHelper from '../../helpers/auth';
import {
  MailOutlined,
  PhoneOutlined,
  EnvironmentOutlined,
  UserOutlined,
  CarOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import OrderVehiclesDetails from './orderVehiclesDetail';
import TimeLine from './orderTimeline';
import ReceiptModal from './ReceiptModal';
import OrderNotesModal from './OrderNotesModal';
import { CALL_ME_OPTIONS } from '../../helpers/app-constants';
import { FUEL_CATEGORIES } from '../../domains/operations/supply/trucks/Model/Truck';
import { Order } from '../../models/Order';
import { Pilot } from '../../models/Pilots';

const formatJobTime = (dateTime: string) => moment(dateTime).format('hh:mm a');

interface OrderDetailProps {
  order: Order;
  onTaskUpdateHandler: () => void;
  country_id?: number;
  type?: FUEL_CATEGORIES | undefined;
  viewLocationOnMap?: (event: TimelineEvent) => void;
  closeDrawer?: () => void;
}

const { Sider } = Layout;
const { TabPane } = Tabs;

const OrderDetail: React.FC<OrderDetailProps> = ({
  order,
  onTaskUpdateHandler,
  closeDrawer,
  country_id,
  type,
  viewLocationOnMap,
}) => {
  const [
    statusChangeModalVisibility,
    setStatusChangeModalVisibility,
  ] = useState(false);
  const [orderDetail, setOrderDetail] = useState<Order>(order || {});
  const [
    isScheduleChangeModalVisible,
    setScheduleChangeModalVisible,
  ] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [
    isTruckAssignModalVisible,
    setTruckAssignModalModalVisible,
  ] = useState<boolean>(false);
  const [pilotInfo, setPilotInfo] = useState<Pilot>();
  const [isReceiptModalVisible, setIsReceiptModalVisible] = useState(false);
  const [ETA, setETA] = useState('--');
  const [
    isOrderNotesModalVisisble,
    setIsOrderNotesModalVisisble,
  ] = useState<boolean>(false);
  const [
    isCustomerPhoneNumberVisible,
    setIsCustomerPhoneNumberVisible,
  ] = useState<boolean>(true);
  const [canCallCustomer, setCanCallCustomer] = useState<boolean>(true);

  useEffect(() => {
    setOrderDetail(order);
  }, [order]);

  // function fetchOrderDetail(order: Job) {
  //   setLoading(true);
  //   if (order) {
  //     const payload = {
  //       access_token: authHelper.getAccessToken(),
  //       user_id: authHelper.getUserID(),
  //       pickup_delivery_relationship: order.pickup_delivery_relationship,
  //       job_id: order.job_id,
  //       gzip: '1',
  //     };

  //     supplyAPI.fetchOrderDetail(payload).then((response) => {
  //       setOrderDetail(response[0]);
  //       setLoading(false);
  //     });
  //   } else {
  //     setLoading(false);
  //   }
  // }

  // function fetchTruckDetail(truckID: number) {
  //   setLoading(true);
  //   if (order) {
  //     const payload = {
  //       access_token: authHelper.getAccessToken(),
  //       fleet_id: truckID,
  //       date: moment().format(),
  //       timezone: -240,
  //       is_current_day: true,
  //       is_future_date: false,
  //     };

  //     supplyAPI.fetchTruckInfo(payload).then(response => {
  //       setPilotInfo(
  //         response?.assignmentInfo ? response.assignmentInfo[0] : undefined
  //       );
  //       setLoading(false);
  //     });
  //   } else {
  //     setLoading(false);
  //   }
  // }

  const fetchETA = async () => {
    try {
      setLoading(true);
      // const response = await scheduleAPI.getOrderETA(order.id);
      // const liveETA = response.directions?.duration?.value;
      // setETA(
      //   liveETA ? new Date(liveETA * 1000).toISOString().substr(11, 8) : '--'
      // );
      setLoading(false);
    } catch (error) {
      message.error('Unable to fetch ETA for ' + order.id);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (orderDetail) {
      setIsCustomerPhoneNumberVisible(true);
      setCanCallCustomer(true);
    }
  }, [orderDetail]);

  // const isCustomerCallAllowed = (
  //   taskDescription: string,
  //   startTime: string
  // ) => {
  //   const isNightOrder = Utils.isNightOrder(startTime);
  //   if (!isNightOrder) {
  //     return true;
  //   }
  //   if (!taskDescription) {
  //     return false;
  //   } else {
  //     CALL_ME_OPTIONS.forEach(callMeOption => {
  //       if (taskDescription.includes(callMeOption)) {
  //         return true;
  //       }
  //     });
  //     return false;
  //   }
  // };

  // useEffect(() => {
  //   fetchOrderDetail(order);
  //   fetchTruckDetail(parseInt(order.fleet_id));
  //   if (isOrderAcceptedOrStarted(order.job_status)) {
  //     fetchETA();
  //   }
  // }, [order]);

  const isOrderAcceptedOrStarted = (status: string) => {
    return status === JobStatus.placed || status === JobStatus.in_progress;
  };

  const isActiveOrder = isActiveJobStatus(order.status.value);
  const isJobRescheduleApplicable =
    [JobStatus.placed, JobStatus.unassigned].indexOf(order.status.value) > -1;
  const isUnassignedJob = Utils.isUnassignedJob(order.status.value);

  const onOrderNotesSubmitted = () => {
    message.success('Order notes added successfully');
    // fetchOrderDetail(order);
  };

  const onOrderNotesModalClosed = () => {
    setIsOrderNotesModalVisisble(false);
  };

  return (
    <Layout style={{ height: 'calc(100vh - 56px)' }}>
      <Sider className="alert-detail-view-sider" width={250}>
        <p className={styles.orderIdLabel}>{` Order ID #${order.id}`}</p>
        <p className={styles.textValueLarge} style={{ marginTop: '24px' }}>
          {formatJobTime(order.timeslot.begins_at)} -{' '}
          {formatJobTime(order.timeslot.ends_at)}
        </p>
        {/* {isJobRescheduleApplicable &&
          order.job_status !== JobStatus.UNASSIGNED && (
            <span
              className="detail-view-change"
              onClick={() => setScheduleChangeModalVisible(true)}
            >
              Change
            </span>
          )} */}
        <Tabs defaultActiveKey="1" style={{ marginTop: '30px', width: '101%' }}>
          <TabPane tab="Order" key="1">
            <p className={styles.textLabel}>Status</p>
            <p className={styles.textValue}>
              {JobStatus[order.status.value] || order.status.value}
            </p>
            {Utils.isStatusChangeAvailable(order.status.value) && (
              <Button
                type="link"
                className={styles.actionLabel}
                onClick={() => {
                  setStatusChangeModalVisibility(true);
                }}>
                Change
              </Button>
            )}
            {order.status.value === 'cancelled' && (
              <>
                <p className={styles.cancellationReasonText}>
                  Reason: {order.status?.cancellation_reason ?? 'N/A'}
                </p>
              </>
            )}
            <Spin spinning={isLoading}>
              <Row
                className={styles.textLabel}
                style={{ margin: '1.5rem 0 1rem 0' }}>
                <Col>Truck Details</Col>
              </Row>
              <Row>
                <Col span={4}>
                  <UserOutlined
                    style={{
                      fontSize: '14',
                      color: '#000000',
                      marginRight: '1.5rem',
                    }}
                  />
                </Col>
                <Col
                  span={20}
                  style={{
                    textTransform: 'capitalize',
                    textDecoration: 'underline',
                  }}>
                  {orderDetail?.pilot?.first_name || 'N/A'} -{' '}
                  {orderDetail?.pilot?.last_name || 'N/A'}
                </Col>
              </Row>
              <Row style={{ margin: '0.5rem 0' }}>
                <Col span={4}>
                  <PhoneOutlined
                    style={{
                      transform: 'rotate(90deg)',
                      fontSize: '14',
                      color: '#000000',
                      marginRight: '1.5rem',
                    }}
                  />
                </Col>
                <Col span={20} style={{ textDecoration: 'underline' }}>
                  {orderDetail?.pilot?.phone_number ?? 'N/A'}
                </Col>
              </Row>
              <Row>
                <Col span={4}>
                  <CarOutlined
                    style={{
                      fontSize: '14',
                      color: '#000000',
                      marginRight: '1.5rem',
                    }}
                  />
                </Col>
                <Col span={20}>{order.asset?.name || 'N/A'}</Col>
              </Row>
              <Row style={{ margin: '0.5rem 0' }}>
                <Col span={4}>
                  <TeamOutlined
                    style={{
                      fontSize: '14',
                      color: '#000000',
                      marginRight: '1.5rem',
                    }}
                  />
                </Col>
                <Col span={20}>{order.zone?.code || 'N/A'}</Col>
              </Row>
              {/* <div className={styles.viewNoteBlock}>
                <Button
                  type="primary"
                  onClick={() => {
                    setIsOrderNotesModalVisisble(true);
                  }}
                  className={styles.viewNoteButton}
                >
                  View order notes
                </Button>
              </div> */}
            </Spin>
          </TabPane>
          <TabPane tab="Customer" key="2">
            <Spin spinning={isLoading}>
              <Row
                className={styles.textLabel}
                style={{ margin: '1.5rem 0 1rem 0' }}>
                {/* In legacy customer ID is mapped to order_id */}
                <Col>{`Customer ID : ${order.user.id}`}</Col>
              </Row>
              <Row>
                <Col span={4}>
                  <UserOutlined
                    style={{
                      fontSize: '14',
                      color: '#000000',
                      marginRight: '1.5rem',
                    }}
                  />
                </Col>
                <Col
                  span={20}
                  style={{
                    textTransform: 'capitalize',
                    textDecoration: 'underline',
                  }}>
                  {order.user.name}
                </Col>
              </Row>
              <Row style={{ margin: '0.5rem 0' }}>
                <Col span="4" className={styles.textSmall}>
                  <PhoneOutlined
                    style={{
                      transform: 'rotate(90deg)',
                      fontSize: '14',
                      color: '#000000',
                      marginRight: '1.5rem',
                    }}
                  />
                </Col>
                <Col span="20">
                  {!canCallCustomer && (
                    <Row
                      className={styles.customerPhoneVisibility}
                      onClick={() => setIsCustomerPhoneNumberVisible(true)}>
                      {`Customer chose not to be called. Show any way?`}
                    </Row>
                  )}
                  {isCustomerPhoneNumberVisible && (
                    <Row>{order.user?.phone_number ?? 'N/A'}</Row>
                  )}
                </Col>
              </Row>
              {/* <Row style={{ margin: '0.5rem 0' }}> */}
              {/* <Col span="4" className={styles.textSmall}>
                  <MailOutlined
                    style={{
                      fontSize: '14',
                      color: '#000000',
                      marginRight: '1.5rem',
                    }}
                  />
                </Col> */}
              {/* <Col span="20">
                  {{orderDetail['customer_email']
                    ? ` ${orderDetail['customer_email']}`
                    : 'N/A'}}
                </Col> */}
              {/* </Row> */}
              <Row>
                <Col span="4" className={styles.textSmall}>
                  <EnvironmentOutlined
                    style={{
                      fontSize: '14',
                      color: '#000000',
                      marginRight: '1.5rem',
                    }}
                  />
                </Col>
                <Col span="20">{order.address?.address ?? 'N/A'}</Col>
              </Row>
              {orderDetail.items && !!orderDetail.items.length && (
                <Row style={{ margin: '32px 0 64px 0' }}>
                  <Col span="24">
                    <OrderVehiclesDetails orderItems={orderDetail.items} />
                  </Col>
                </Row>
              )}
            </Spin>
          </TabPane>
          {orderDetail.timeline && (
            <TabPane tab="Timeline" key="3">
              <p className={styles.timeLineLabel}>Time line</p>
              <div className={styles.timeLineContainer}>
                <TimeLine events={orderDetail.timeline} />
              </div>
              <hr />
            </TabPane>
          )}
        </Tabs>
        {orderDetail.status.value === 'completed' && (
          <div className={styles.btnsWrapper}>
            {/* {isActiveOrder && (
              <button
                onClick={() => setTruckAssignModalModalVisible(true)}
                className={styles.assignmentButton}
              >
                {isUnassignedJob ? 'Assign Truck' : 'Reassign Truck'}
              </button>
            )} */}
            <button
              onClick={() => setIsReceiptModalVisible(true)}
              className={styles.assignmentButton}>
              Receipt
            </button>
          </div>
        )}
      </Sider>

      {/* {isTruckAssignModalVisible && (
        <AssignTruckModal
          team_id={order.team_id}
          onAssignHandler={onTaskUpdateHandler}
          title={isUnassignedJob ? 'Assign Truck' : 'Reassign Truck'}
          okText={isUnassignedJob ? 'Assign' : 'Reassign'}
          visibility={isTruckAssignModalVisible}
          onModalVisibilityChange={(visibility: boolean) => {
            setTruckAssignModalModalVisible(visibility);
          }}
          country_id={1}
          fuel_type={type}
          job_ids={[order.job_id]}
        />
      )} */}

      {/* {orderDetail.hasOwnProperty('order_id') &&
        isScheduleChangeModalVisible && (
          <ChangeJobScheduleModal
            onUpdateHandler={onTaskUpdateHandler}
            job={order}
            order_id={orderDetail['order_id']}
            closeModal={() => setScheduleChangeModalVisible(false)}
            visible={isScheduleChangeModalVisible}
          />
        )} */}
      {statusChangeModalVisibility && (
        <ChangeJobStatusModal
          onUpdateHandler={onTaskUpdateHandler}
          key={`change-job-status-modal-${order.id}`}
          job_id={order.id}
          closeModal={() => {
            setStatusChangeModalVisibility(false);
          }}
          closeDrawer={closeDrawer}
          visible={statusChangeModalVisibility}
          status={order.status.value}
        />
      )}
      {/* {Object.keys(orderDetail).length > 0 && (
        <OrderNotesModal
          order={orderDetail}
          key={`add-order-notes-modal-${order.job_id}`}
          categories={Object.values(orderDetail['delay_logging_categories'])}
          onClose={() => onOrderNotesModalClosed()}
          onSuccess={() => onOrderNotesSubmitted()}
          visible={isOrderNotesModalVisisble}
        />
      )} */}

      {isReceiptModalVisible && Object.keys(orderDetail).length > 0 && (
        <ReceiptModal
          orderId={orderDetail.id}
          visible={isReceiptModalVisible}
          onClose={() => setIsReceiptModalVisible(false)}
        />
      )}
    </Layout>
  );
};

export default React.memo(OrderDetail);
