import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import applicationLayout from '../../components/layout/Layout';
import BreadCrumbs from '../../components/layout/BreadCrumbs/BreadCrumbs';
import { Table } from 'antd';
import styles from './styles.module.css';
import { Asset } from '../../models/Assets';
import { getAssetsList } from '../../helpers/api';

export const tableColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Plate No.',
    dataIndex: 'plate_number',
  },
  {
    title: 'Zone',
    dataIndex: ['zone', 'code'],
  },
  {
    title: 'Capacity',
    dataIndex: 'capacity',
  },
  {
    title: 'SoC',
    dataIndex: 'state_of_charge',
  },
  {
    title: 'Model / Year',
    dataIndex: 'model',
    key: 'model_year',
    render: (model: string, record: Asset) => {
      return (
        <>
          {record.model} - {record.year}
        </>
      );
    },
  },
];

const AssetsList = () => {
  const [assetsList, setAssetsList] = useState<Asset[]>([]);
  const [isLoadingData, setIsLoadingData] = useState(false);

  useEffect(() => {
    fetchAssets();
  }, []);

  const fetchAssets = async () => {
    try {
      setIsLoadingData(true);
      const response = await getAssetsList();
      setAssetsList(response?.data?.data ?? []);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingData(false);
    }
  };

  return (
    <main className={styles.content}>
      <BreadCrumbs items={[<Link to="/home">Dashboard</Link>, 'Pilots']} />
      <div className={styles.headerGrid}>
        <div className={styles.pageTitle}>Assets</div>
      </div>

      <Table
        dataSource={assetsList}
        columns={tableColumns}
        rowKey="id"
        rowClassName={styles.pilotsTable__row}
        loading={isLoadingData}
      />
    </main>
  );
};

export default applicationLayout(AssetsList);
