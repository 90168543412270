import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router';
import * as authHelper from '../helpers/auth';

interface OwnProps {
  action: string;
  TargetComponent: React.ComponentType<any>;
}

type Props = OwnProps & RouteProps;

function AuthorizedRoute(props: Props) {
  const { TargetComponent, action, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(props) =>
        authHelper.isLoggedIn() ? (
          <TargetComponent {...props} />
        ) : authHelper.isAzureAvailable() ? (
          <Redirect to={'/login'} />
        ) : (
          <Redirect to={'/loginwithusername'} />
        )
      }
    />
  );
}
export default AuthorizedRoute;
