import moment from 'moment';

export function convertDateTimeToCurrentZone(
  dateTime: string,
  timeZoneOffset: string
) {
  return moment(dateTime).format('hh:mm A');
}

export function computeDelay(dateTime: string, timeZoneOffset: string) {
  let d = moment.duration(
    moment(new Date(), 'YYYY/MM/DD HH:mm:ss').diff(dateTime)
  );
  var seconds = d.hours() * 3600 + d.minutes() * 60 + d.seconds();
  return secondsToHms(seconds);
}

export function secondsToHms(d: number) {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);

  var hDisplay = h > 0 ? h + (h === 1 ? ' hour' : ' hours') : '';
  var mDisplay = m > 0 ? m + (m === 1 ? ' minute' : ' minutes') : '';
  return [hDisplay, mDisplay].filter(Boolean).join(', ');
}

export function getTodaysDate() {
  return moment().format('YYYY-MM-DD');
}

export const getUTCDate = (offset: number) => {
  // create Date object for current location
  const date = new Date();

  // convert to msec
  // subtract local time zone offset
  // get UTC time in msec
  const utc = date.getTime() + date.getTimezoneOffset() * 60000;

  // create new Date object for different city
  // using supplied offset
  const utcDate = new Date(utc + 3600000 * offset);

  return utcDate;
};
