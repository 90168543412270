import { Order } from './Order';

export interface AgentView {
  line0: string;
  line1: string;
  line2: string;
}

export interface Fleet {
  agent_view: AgentView;
  avg_cust_rating: number;
  battery_level: number | null;
  bearing: string | null;
  country_code: string | null;
  code: string | null;
  device_desc: string | null;
  device_os: string | null;
  device_token: string | null;
  device_type?: string | null;
  driver_id: number | null;
  driver_name: string | null;
  first_name: string | null;
  fleet_id: number;
  fleet_image: string | null;
  fleet_name: string;
  fleet_status_color: string | null;
  fleet_thumb_image: string | null;
  has_gps_accuracy: number | null;
  has_mock_loc: number | null;
  has_network: number | null;
  is_active: number;
  is_available: number;
  is_deleted: number | null;
  last_name: string | null;
  last_updated_location_time: string;
  latitude: string;
  license: string | null;
  longitude: string;
  phone: string | null;
  registration_status: number;
  status: number;
  store_version: string | null;
  tags: string | null;
  team_id: number | null;
  team_region_id: number | null;
  total_rated_tasks: number | null;
  total_rating: number | null;
  truck_name: string;
  user_id: number | null;
  user_name: string | null;
  username: string | null;
  zone: string | null;
  country_id: number;
  type: string;
  offline_reason: string;
}

export interface Job {
  address: string;
  completed_datetime: string;
  completed_datetime_local: string;
  customer_username: string;
  fleet_id: string;
  fleet_name: string;
  fleet_thumb_image: string;
  fuel_name_count: { [key: string]: number };
  is_routed: number;
  is_scheduled: number;
  job_delivery_datetime: string;
  job_id: number;
  job_pickup_datetime: string;
  job_pickup_name: string;
  job_status: string;
  job_time: string;
  job_time_milliseconds?: number;
  job_type: number;
  latitude: string;
  longitude: string;
  no_of_vehicles: number;
  pickup_delivery_relationship: string;
  started_datetime: string;
  subscription_id: null;
  tags: string;
  team_id: number;
  team_name: string;
  country_id: number;
}

export interface Task {
  agent_row_image: string;
  appointment_job_index: number;
  auto_assignment: number;
  completed_job_count: number;
  delivery_count: number;
  delivery_job_index: number;
  fos_job_index: number;
  jobs: Array<Job>;
  partner_order_id: number;
  pickup_count: number;
  pickup_delivery_relationship: string;
  pickup_job_index: number;
  task_id: number;
  task_status: string;
  task_view: {};
  total_job_count: number;
}
interface TasksFleets {
  fleets: {
    [key: string]: Fleet;
  };
  jobs: Array<Task[]>;
}
export default interface Tasks {
  fleet_data: {
    active_fleets: number;
    total_fleets: number;
    unassigned_fleets: number;
    unavailable_fleets: number;
  };
  job_counts: {
    assigned_job: number;
    completed_job: number;
    unassigned_job: number;
  };
  teams: {
    [key: string]: TasksFleets;
  };
}

export interface ReassignTaskPayload {
  fleet_id: string;
  job_id: number;
  team_id: number;
  job_status: string;
  assignment_reason?: string;
}

export function ordersFilter(filter: string): (data: Order) => boolean {
  return ({ user, id }) =>
    id.toString().includes(filter) || user.name.toLowerCase().includes(filter);
}

export function truckFilter(filter: string): (data: Fleet) => boolean {
  return ({ driver_name, truck_name, license, driver_id, fleet_id, zone }) =>
    fleet_id.toString().includes(filter) ||
    driver_name?.toLowerCase().includes(filter) ||
    truck_name?.toLowerCase().includes(filter) ||
    license?.toLowerCase().includes(filter) ||
    driver_id?.toString().includes(filter) ||
    zone?.toLowerCase().includes(filter)
      ? true
      : false;
}

export interface TimelineEvent {
  creation_date: string;
  creation_datetime: string;
  description: string;
  extra_fields: null;
  fleet_id: string | null;
  fleet_name: string | null;
  id: number;
  job_id: number;
  job_status: string | null;
  label_description: string;
  latitude: number | null;
  longitude: number | null;
  type: string;
  user_id: number | null;
}
