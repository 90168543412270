import { LayerProps } from 'react-map-gl';
export const ordersLayer: LayerProps = {
  id: 'orders',
  type: 'symbol',
  source: 'orders',
  layout: {
    'icon-image': 'accepted-icon',
    'icon-size': [
      'step',
      ['zoom'],
      ['match', ['get', 'status'], 99, 2.2, 1.5],
      14,
      ['match', ['get', 'status'], 99, 2.2, 2.0],
      16,
      ['match', ['get', 'status'], 99, 2.2, 2.4],
    ],
    'icon-allow-overlap': true,
  },
  paint: {
    'icon-color': [
      'match',
      ['get', 'status'],
      3,
      '#BA68C8',
      6,
      '#757D8A',
      7,
      '#2196F3',
      8,
      '#3F51B5',
      99,
      '#fff',
      '#BA68C8',
    ],
    'icon-halo-width': ['match', ['get', 'status'], 99, 6, 3],
    'icon-halo-color': ['match', ['get', 'status'], 99, '#40A8F1', '#ffffff'],
  },
};
