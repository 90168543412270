import * as React from 'react';
import { useState } from 'react';
import { Layout } from 'antd';
import styles from './layout.module.css';
import LayoutContext, { MarketType } from './LayoutContext';
import TopBar from './TopBar';
import * as Utils from '../../helpers/utils';

/**
 * This is a High Order Component function that used to apply the application
 * layout to a certain component
 * @param Component
 * @returns Component with proper layout
 */

const applicationLayout = <P extends object>(
  Component: React.ComponentType<P>
): React.ComponentType<P> =>
  function ApplicationLayout(props: P) {
    const [market, setMarket] = useState<MarketType>({
      country: Utils.getSelectedCountry() || 1,
      fuelType: Utils.getSelectedFuelType() || 0,
      refreshData: false,
    });
    const [refreshedData, setRefreshData] = useState<boolean>(false);

    const selectCountry = (value: number) => {
      setMarket({ ...market, country: value });
      Utils.setSelectedCountry(value);
    };
    const selectFuelType = (value: number) => {
      setMarket({ ...market, fuelType: value });
      Utils.setSelectedFuelType(value);
    };
    const refreshData = () => {
      setRefreshData(!refreshedData);
      setMarket({ ...market, refreshData: !refreshedData });
    };
    return (
      <Layout className={styles.application_layout_container}>
        <LayoutContext.Provider value={market}>
          <TopBar
            selectCountry={selectCountry}
            selectFuelType={selectFuelType}
            refreshData={refreshData}
          />
          <Layout
            style={{ minHeight: 'calc(100vh - 56px)', overflow: 'scroll' }}
          >
            <Component {...(props as P)} />
          </Layout>
        </LayoutContext.Provider>
      </Layout>
    );
  };

export default applicationLayout;
