import React, { ReactNode } from 'react';
import { Breadcrumb } from 'antd';

import styles from '../BreadCrumbs/BreadCrumbs.module.css';

interface Props {
  items: ReactNode[];
}

const Breadcrumbs = ({ items }: Props): JSX.Element => (
  <Breadcrumb className={styles.item} separator=" / ">
    {items.map((item, index) => (
      <Breadcrumb.Item key={index}>{item}</Breadcrumb.Item>
    ))}
  </Breadcrumb>
);

export default Breadcrumbs;
