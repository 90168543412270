import * as React from 'react';
import { Layout, Menu } from 'antd';
import { SettingsPanel } from '../SettingsPanel';
import styles from './layout.module.css';
import { Link, useLocation } from 'react-router-dom';

interface TopBarProps {
  selectCountry: (value: number) => void;
  selectFuelType: (value: number) => void;
  refreshData: () => void;
}

const { Header } = Layout;

const TopBar = function (props: TopBarProps) {
  const location = useLocation();
  const currentPagePath = location.pathname;
  const { selectCountry, selectFuelType, refreshData } = props;

  return (
    <Header className={styles.layoutHeader}>
      <Link to="/">
        <img
          width="64px"
          className="logo"
          alt="cafu-logo"
          src="https://dashboard.cafu.app/app/img/CAFU_logo.svg"
        />
      </Link>

      <Menu
        className={styles.navMenu}
        theme="dark"
        mode="horizontal"
        defaultSelectedKeys={[currentPagePath]}
        selectedKeys={[currentPagePath]}>
        <Menu.Item key="/home">
          <Link to="/home">Home</Link>
        </Menu.Item>

        <Menu.Item key="/b2c/orders">
          <Link to="/b2c/orders">Orders</Link>
        </Menu.Item>

        <Menu.Item key="/pilots">
          <Link to="/pilots">Pilots</Link>
        </Menu.Item>

        <Menu.Item key="/assets">
          <Link to="/assets">Assets</Link>
        </Menu.Item>

        <SettingsPanel
          selectCountry={selectCountry}
          selectFuelType={selectFuelType}
          refresh={refreshData}
        />
      </Menu>
    </Header>
  );
};

export default TopBar;
