import * as React from 'react';
import moment from 'moment';
import { Timeline } from 'antd';
import '../../../src/css/Dashboard.css';
import styles from './orderTimeline.module.css';
import { OrderTimeline } from '../../models/Order';
interface TimelinProps {
  events: Array<OrderTimeline>;
}

function TimeLine(props: TimelinProps) {
  const { events } = props;
  const filterEvents = events;
  return (
    <>
      <Timeline mode="alternate" reverse={true}>
        {filterEvents &&
          filterEvents.map(event => (
            <Timeline.Item
              position="left"
              key={event.created_at}
              color="#1890FF"
              className={styles.timeline}
              label={
                <div className={styles.timeLineItemLabel}>
                  <span>{moment(event.created_at).format('DD MMM')}</span>
                  <br></br>
                  <span>{moment(event.created_at).format('hh:mm a')}</span>
                </div>
              }>
              <p className={styles.timeLineItemContent}>{event.status}</p>
              <p className={styles.timelineLocationLabel}></p>
            </Timeline.Item>
          ))}
      </Timeline>
    </>
  );
}

export default React.memo(TimeLine);
