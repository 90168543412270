import React from 'react';
import { useState, useRef, useEffect, useImperativeHandle } from 'react';
import ReactMapGL, {
  ViewportProps,
  MapLoadEvent,
  PointerEvent,
  InteractiveMap,
  Source as MapSource,
  Layer as MapLayer,
  ExtraState,
  FlyToInterpolator,
  Popup,
  WebMercatorViewport,
} from 'react-map-gl';
import { spritesheet } from '@elastic/maki';
import sprites from '@elastic/maki/dist/sprite@2.png';
import { RGBAImage } from '../map-utils/image';

import { point as turfPoint, featureCollection } from '@turf/helpers';
import bbox from '@turf/bbox';
import { FeatureCollection } from 'geojson';
import { Job, Fleet } from '../../models/Task';
import Zone, { Point, toZoneGeoJson } from '../../models/Zone';
import { ordersLayer } from '../../orders/layers/orders-layer';
import {
  zonesLayersOutline,
  zonesLayers,
  allZonesLayer,
} from '../layers/zone-layer';
import { trucksLayer } from '../layers/trucks-layer';
import { BaseMapStyle } from './default-map-style';
import { alertsLayer } from '../layers/alerts-layer';
import {
  addPulseAnimation,
  fleetStatusColor,
  addFleetMarker,
} from '../map-utils/pulsing-icon';
import OrderMapPopOver, {
  OrderPopUp,
  AlertPopUp,
  FleetPopUp,
  DefaultPopUp,
  TruckMapPopOver,
  OrderEventPopUp,
} from './components/MapPopOver';
import { Alert, AlertItem } from '../../models/Alert';
import {
  ZONE_HEALTH_VIEW_ZOOM,
  NO_ORDERS_ZOOM,
  REACT_APP_DEFAULT_MAP_ZOOM,
  REACT_APP_MAPBOX_TOKEN,
} from '../../helpers/app-constants';
import { ShiftInfo } from '../../models/Schedule';
import { Order } from '../../models/Order';
import { Asset } from '../../models/Assets';
import { JobStatusToID } from '../../models/JobStatus';

let lastFeature: any = null;

const spriteData = spritesheet['2'];
const zoneHealthScores: any = {};
export interface MapRefCaller {
  hideLayers: (layers: string, visibility: boolean) => void;
  toggleMapStyle: (style: string) => void;
  goToViewport: (zoom?: number, center?: GeoJSON.Point) => void;
  adjustViewport: (padding: mapboxgl.PaddingOptions) => void;
  getZoomLevel: () => number;
}

interface MapStyle {
  [key: string]: string;
}
export const MapTileStyles: MapStyle = {
  satellite: 'mapbox://styles/mapbox/satellite-v9',
  dark: 'mapbox://styles/uberdata/cjoqbbf6l9k302sl96tyvka09',
  default: 'mapbox://styles/emel123/ck9b6jijw0t2v1ipbicji6jvj',
  defaultDark: 'mapbox://styles/mapbox/dark-v10',
};
const interactiveLayers = ['orders', 'pilots'];
const DEFAULT_VIEWPORT: ViewportProps = {
  height: window.innerHeight - 56,
  width: window.innerWidth,
  latitude: 45.5590971,
  longitude: -73.8519555,
  zoom: parseInt(REACT_APP_DEFAULT_MAP_ZOOM!) || 10,
  bearing: 0,
  pitch: 0,
  altitude: 0,
  maxZoom: 20,
  minZoom: 2,
  maxPitch: 60,
  minPitch: 0,
};
interface MapProps {
  mapStyle: BaseMapStyle;
  job?: Order;
  orders: Order[];
  fleets: Asset[];
  zones: Zone[];
  onHover?: (event: PointerEvent) => void;
  onMouseMove?: (event: PointerEvent) => void;
  onOrderClick: (jobID: number) => void;
  onTruckClick?: (truckID: number) => void;
  children?: React.ReactNode;
  updateFleetLocationHandler: (map: any) => void;
  selectedFeature?: GeoJSON.Feature;
  mapSelectedOrders?: Order[];
  selectedOrder?: Order;
  updateZoomLevel: (zoomLevel: number) => void;
  fleetShiftMap: Map<string, ShiftInfo>;
}

const getChargeLevel = (charge: number) => {
  if (charge >= 50) {
    return 'high-charge-truck';
  }

  return 'low-charge-truck';
};

const MapComponent = React.forwardRef<MapRefCaller, MapProps>((props, ref) => {
  const {
    onOrderClick,
    orders,
    mapStyle,
    fleets,
    zones,
    updateFleetLocationHandler,
    onTruckClick,
    selectedFeature,
    mapSelectedOrders,
    selectedOrder,
    updateZoomLevel,
    fleetShiftMap,
  } = props;

  const [paddingOptions, setPaddingOptions] = useState({
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  });
  const [, setDynamicMapStyle] = useState(mapStyle);
  const [viewport, setViewport] = useState(DEFAULT_VIEWPORT);

  const [baseMapTilesStyle, setBaseMapTilesStyle] = useState(
    localStorage.getItem('ewd-base-map-style') || MapTileStyles.default
  );
  const [baseMap, setMap] = useState<mapboxgl.Map>();

  const mapRef = useRef<InteractiveMap>(null);
  const [ordersGEOJSON, setOrdersGeoJSON] = useState<
    | GeoJSON.Feature<GeoJSON.Geometry>
    | GeoJSON.FeatureCollection<GeoJSON.Geometry>
    | string
  >();
  const [alertsGeoJson, setAlertsGeoJSON] = useState<
    | GeoJSON.Feature<GeoJSON.Geometry>
    | GeoJSON.FeatureCollection<GeoJSON.Geometry>
    | string
  >();
  const [zonesGEOJson, setZonesGeoJSON] = useState<
    | GeoJSON.Feature<GeoJSON.Geometry>
    | GeoJSON.FeatureCollection<GeoJSON.Geometry>
    | string
  >();
  const [trucksGeoJson, setTrucksGeoJson] = useState<
    | GeoJSON.Feature<GeoJSON.Geometry>
    | GeoJSON.FeatureCollection<GeoJSON.Geometry>
    | string
  >();
  const [hoveredFeature, setHoveredFeature] = useState<GeoJSON.Feature>();
  const [hoveredOrder, setHoveredOrderFeature] = useState<GeoJSON.Feature>();
  const [hoveredTruck, setHoveredTruckFeature] = useState<GeoJSON.Feature>();
  const [hoveredOrderPosition, setHoveredOrderPosition] = useState<
    Array<number>
  >();
  const [hoveredZone, setHoveredZone] = useState<any>();

  const [popUpFeature, setSelectedFeature] = useState<
    GeoJSON.Feature | undefined
  >(selectedFeature);
  const clusterRef = useRef<MapSource>(null);
  const trucksRef = useRef<MapSource>(null);
  const zonesRef = useRef<MapSource>(null);

  useEffect(() => {
    setSelectedFeature(selectedFeature);
    if (selectedFeature) {
      const center = selectedFeature.geometry as GeoJSON.Point;
      const viewPortZoom = viewport.zoom;
      goToViewport(viewPortZoom < 14 ? viewPortZoom + 2 : viewPortZoom, center);
    } else {
      //revisit this - not sure if we really want to reset viewport as well.
      // setViewport(props.viewport);
    }
  }, [selectedFeature]);

  function hideLayers(layerId: string, visibility: boolean) {
    if (baseMap) {
      if (visibility) {
        baseMap.setLayoutProperty(layerId, 'visibility', 'visible');
      } else {
        baseMap.setLayoutProperty(layerId, 'visibility', 'none');
      }
    }
  }
  function toggleMapStyle(style: string) {
    if (baseMap) {
      setBaseMapTilesStyle(MapTileStyles[style]);
    }
  }

  function adjustViewport(padding: mapboxgl.PaddingOptions) {
    if (baseMap) {
      baseMap.setPadding(padding);
      setPaddingOptions(padding);
    }
  }

  const getZoomLevel = () => {
    if (mapRef && mapRef.current != null) {
      const map = mapRef.current.getMap();
      const zoom = map.getZoom();
      return zoom;
    }
    return DEFAULT_VIEWPORT.zoom;
  };

  useEffect(() => {
    localStorage.setItem('ewd-base-map-style', baseMapTilesStyle);
  }, [baseMapTilesStyle]);

  useImperativeHandle(ref, () => ({
    hideLayers,
    toggleMapStyle,
    goToViewport,
    adjustViewport,
    getZoomLevel,
  }));

  useEffect(() => {
    updateZoomLevel(getZoomLevel());
  }, [viewport]);

  function toOrdersGeoJSON(tasks: Array<Order>) {
    return {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: tasks.map(job => {
          const isSelectedOnMap = mapSelectedOrders?.find(
            selectedOrder => selectedOrder.id === job.id
          );
          const isSelectedOrder = selectedOrder?.id === job.id;
          return {
            type: 'Feature',
            id: job.id,
            properties: {
              customer: job.user.name,
              id: job.id,
              status:
                isSelectedOnMap || isSelectedOrder
                  ? 99
                  : JobStatusToID[job.status.value], // Using 99 to represent a selected order status
              // fleet: job.fleet_name,
              type: 'order',
              // fuel_count: job.fuel_name_count,
              start_time: job.timeslot.begins_at,
              end_time: job.timeslot.ends_at,
            },
            geometry: {
              type: 'Point',
              coordinates: [job.address.lng, job.address.lat],
            },
          };
        }),
      },
    };
  }
  function mapOrders() {
    const ordersGeoJSON = toOrdersGeoJSON(orders);
    setOrdersGeoJSON(ordersGeoJSON.data as FeatureCollection);
  }

  useEffect(() => {
    mapOrders();
  }, [orders, mapSelectedOrders, selectedOrder]);

  function truckMap(task: Asset) {
    return {
      type: 'Feature',
      id: task.id,
      geometry: {
        type: 'Point',
        coordinates: [task.location.lng, task.location.lat],
      },
      properties: {
        license: task.plate_number,
        phone: task.pilot?.phone_number ?? '',
        type: 'truck',
        code: task.name,
        zone: task.zone?.code ?? '',
        id: task.id,
        fleet_status_color: getChargeLevel(task.state_of_charge),
        fleet_alert: task.is_low_charge,
        capacity: task.capacity,
        stateOfCharge: task.state_of_charge,
        modelYear: `${task.model} - ${task.year}`,
      },
    };
  }

  function toFleetGeoJson(fleets: Array<Asset>) {
    return {
      pilots: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: fleets.map(truckMap),
        },
      },
    };
  }

  function mapFleets() {
    const fleetGeoJSON = toFleetGeoJson(fleets);
    setTrucksGeoJson(fleetGeoJSON.pilots.data as FeatureCollection);
    mapStyle.sources['pilots'] = fleetGeoJSON.pilots;
    setDynamicMapStyle(mapStyle);
  }

  useEffect(() => {
    mapFleets();
  }, [fleets]);

  function getImageData(img: any) {
    const canvas = window.document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context) {
      throw new Error('failed to create canvas 2d context');
    }
    canvas.width = img.width;
    canvas.height = img.height;
    context.drawImage(img, 0, 0, img.width, img.height);
    return context.getImageData(0, 0, img.width, img.height);
  }

  function onLoad(e: MapLoadEvent) {
    (async e => {
      const map = e.target || baseMap;
      setMap(map);

      const image = new Image();
      image.onload = el => {
        const imageData = getImageData(el.currentTarget);
        const { width, height, x, y, sdf, pixelRatio } = spriteData[
          'marker-11'
        ];
        const data = new RGBAImage({ width, height });
        RGBAImage.copy(
          imageData,
          data,
          { x, y },
          { x: 0, y: 0 },
          { width, height }
        );
        // result[id] = { data, pixelRatio, sdf };
        map.addImage('accepted-icon', data, { pixelRatio, sdf });
      };
      image.src = sprites;

      // implementation of CustomLayerInterface to draw a pulsing dot icon on the map
      // see https://docs.mapbox.com/mapbox-gl-js/api/#customlayerinterface for more info

      let pulsingAlert = addPulseAnimation(
        map,
        'rgba(246, 36, 89, ${1 - t})',
        'rgba(246, 36, 89, 1)'
      );
      map.addImage('pulsing-alert', pulsingAlert, { pixelRatio: 2 });

      let lowChargeTruck = addFleetMarker('rgba(153, 153, 153, 1)');
      map.addImage('low-charge-truck', lowChargeTruck, { pixelRatio: 2 });

      let highChargeTruck = addFleetMarker('rgba(99, 174, 12, 1)');
      map.addImage('high-charge-truck', highChargeTruck, { pixelRatio: 2 });

      updateFleetLocationHandler(trucksRef);
    })(e);
  }

  async function fetchZones() {
    const geoJsonZones = toZoneGeoJson(zones);
    setZonesGeoJSON(geoJsonZones.zones.data as FeatureCollection);
    mapStyle.sources['zones'] = geoJsonZones.zones;
    setDynamicMapStyle(mapStyle);
  }

  function computeViewPort(zones: Zone[]) {
    let viewport = DEFAULT_VIEWPORT;
    let points: any = [];
    zones.forEach((zone: Zone) => {
      if (
        zone.region_id === 58 ||
        zone.region_id === 34 ||
        zone.region_id === 59
      ) {
        return;
      }
      zone.region_data.forEach((point: Point) => {
        points.push(turfPoint([point.x, point.y]));
      });
    });
    let features = featureCollection(points);
    const [minLng, minLat, maxLng, maxLat] = bbox(features);
    // construct a viewport instance from the current state
    const new_viewport = new WebMercatorViewport(viewport);
    if (1 / minLng && 1 / minLat && 1 / maxLng && 1 / maxLat) {
      const { longitude, latitude, zoom } = new_viewport.fitBounds(
        [
          [Number(minLat), Number(minLng)],
          [Number(maxLat), Number(maxLng)],
        ],
        { padding: { top: 0, left: 0, right: 300, bottom: 0 } }
      );
      setViewport({
        ...viewport,
        longitude,
        latitude,
        zoom: NO_ORDERS_ZOOM,
      });
    }
    return viewport;
  }

  useEffect(() => {
    fetchZones();
    computeViewPort(zones);
  }, [zones]);

  const goToViewport = (
    zoom: number = DEFAULT_VIEWPORT.zoom,
    center: GeoJSON.Point | undefined
  ) => {
    setViewport({
      ...viewport,
      longitude:
        center?.coordinates[0] ||
        viewport.longitude ||
        DEFAULT_VIEWPORT.longitude,
      latitude:
        center?.coordinates[1] ||
        viewport.latitude ||
        DEFAULT_VIEWPORT.latitude,
      zoom: zoom,
      transitionInterpolator: new FlyToInterpolator({ speed: 1.5 }),
      transitionDuration: 1000,
    });
  };

  function onMapClick(event: PointerEvent) {
    const { point, lngLat } = event;

    if (mapRef && mapRef.current != null) {
      const map = mapRef.current.getMap();
      if (!map.getLayer('orders')) return;

      const taskFeatures = map.queryRenderedFeatures(point, {
        layers: ['orders', 'pilots', 'dubai-zones'],
      });
      setSelectedFeature(undefined);
      if (taskFeatures.length && map.getZoom() < 24 && !map.isMoving()) {
        const zoom = map.getZoom();
        const feature = taskFeatures[0];
        const center = feature.geometry;

        if (zoom <= 14) {
          // In case of trucks/orders pins
          if (center.type === 'Point') {
            goToViewport(zoom, center);
          }

          // Case of a zone click
          if (center.type === 'Polygon') {
            const zoneCenterPoint: GeoJSON.Point = {
              type: 'Point',
              coordinates: lngLat,
            };
            goToViewport(11.5, zoneCenterPoint);
          }
        }

        const featureProperties = feature?.properties;
        if (featureProperties) {
          switch (featureProperties.type) {
            case 'order': {
              onOrderClick(featureProperties.id);
              break;
            }
            // case 'truck': {
            //   onTruckClick(featureProperties.id);
            //   break;
            // }
            default:
              break;
          }
        }
        lastFeature = feature;
      }
    }
  }

  function onHover(event: PointerEvent) {
    const {
      features,
      srcEvent: { offsetX, offsetY },
    } = event;

    const hoveredFeature =
      features &&
      features.find(f =>
        interactiveLayers.some(Ilayer => Ilayer === f.layer.id)
      );
    if (hoveredFeature) {
      setHoveredFeature(hoveredFeature);
      switch (hoveredFeature?.properties!.type) {
        case 'order':
          setHoveredOrderFeature(hoveredFeature);
          setHoveredOrderPosition([offsetX, offsetY]);
          break;
        case 'truck':
          setHoveredTruckFeature(hoveredFeature);
          setHoveredOrderPosition([offsetX, offsetY]);
          break;
        case 'zone':
          setHoveredZone(hoveredFeature);
          break;
        default:
          setHoveredFeature(undefined);
          setHoveredTruckFeature(undefined);
          setHoveredOrderPosition(undefined);
          break;
      }
    } else {
      setHoveredFeature(undefined);
      setHoveredOrderFeature(undefined);
      setHoveredOrderPosition(undefined);
      setHoveredZone(undefined);
    }
  }

  const renderOrderToolTip = () => {
    if (!hoveredFeature) {
      return;
    }

    if (hoveredOrder && popUpFeature && hoveredOrder?.id === popUpFeature?.id) {
      return null;
    }

    switch (hoveredFeature?.properties!.type) {
      case 'order':
        return hoveredOrder && hoveredOrderPosition ? (
          <OrderMapPopOver
            hoveredFeature={hoveredOrder}
            hoveredFeaturePosition={hoveredOrderPosition}
          />
        ) : (
          ''
        );
      case 'truck':
        return hoveredTruck && hoveredOrderPosition ? (
          <TruckMapPopOver
            hoveredFeature={hoveredTruck}
            hoveredFeaturePosition={hoveredOrderPosition}
          />
        ) : (
          ''
        );

      default:
        return '';
    }
  };

  const getCursor = (state: ExtraState) => {
    const { isDragging, isHovering } = state;
    return isHovering || isDragging ? 'pointer' : 'default';
  };

  const popUpPinInfo = () => {
    if (popUpFeature) {
      switch (popUpFeature?.properties!.type) {
        case 'order':
          return <OrderPopUp popUpFeature={popUpFeature} />;
        case 'alert':
          return <AlertPopUp popUpFeature={popUpFeature} />;
        case 'fleet':
          return <FleetPopUp popUpFeature={popUpFeature} />;
        case 'orderEvent':
          return <OrderEventPopUp popUpFeature={popUpFeature} />;
        default:
          return <DefaultPopUp popUpFeature={popUpFeature} />;
      }
    }
  };
  const renderPopup = () => {
    const location = popUpFeature!.geometry as GeoJSON.Point;
    return (
      location && (
        <Popup
          tipSize={6}
          anchor="bottom"
          longitude={location.coordinates[0]}
          latitude={location.coordinates[1]}
          offsetTop={-10}
          offsetLeft={paddingOptions.right > 0 ? -200 : 0}
          closeOnClick={false}
          onClose={() => {
            setSelectedFeature(undefined);
          }}>
          {popUpPinInfo()}
        </Popup>
      )
    );
  };

  return (
    <ReactMapGL
      {...viewport}
      ref={mapRef}
      scrollZoom={true}
      mapStyle={baseMapTilesStyle}
      mapboxApiAccessToken={`${REACT_APP_MAPBOX_TOKEN}`}
      onViewportChange={viewport => setViewport(viewport)}
      onLoad={onLoad}
      onHover={onHover}
      getCursor={getCursor}
      interactiveLayerIds={interactiveLayers}
      onClick={(event: PointerEvent) => onMapClick(event)}
      width="100%">
      {props.children}
      <MapSource type="geojson" data={trucksGeoJson} ref={trucksRef}>
        <MapLayer minzoom={NO_ORDERS_ZOOM} {...trucksLayer} />
      </MapSource>
      <MapSource type="geojson" data={ordersGEOJSON} ref={clusterRef}>
        <MapLayer minzoom={NO_ORDERS_ZOOM} beforeId="pilots" {...ordersLayer} />
      </MapSource>
      <MapSource type="geojson" data={alertsGeoJson} ref={clusterRef}>
        <MapLayer beforeId="pilots" {...alertsLayer} />
      </MapSource>
      <MapSource type="geojson" data={zonesGEOJson} ref={zonesRef}>
        <MapLayer
          maxzoom={ZONE_HEALTH_VIEW_ZOOM}
          beforeId="orders"
          {...zonesLayers}
        />
        <MapLayer beforeId="orders" {...zonesLayersOutline} />
        <MapLayer beforeId="orders" {...allZonesLayer} />
      </MapSource>
      {renderOrderToolTip()}
      {popUpFeature && renderPopup()}
    </ReactMapGL>
  );
});

export default MapComponent;
