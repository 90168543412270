import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import App from './App';
import AuthorizedRoute from './components/AuthorizedRoute';
import Home from './domains/home/Home';
import LoginWithUsernamePassword from './views/LoginWithUsernamePassowrd';
import OrdersList from './domains/orders/OrdersList';
import PilotsList from './domains/pilots/PilotsList';
import AssetsList from './domains/assets/AssetsList';

function AppRouter() {
  function renderDashboard(): JSX.Element {
    return (
      <Switch>
        <Route exact path="/" component={App} />
        <Route
          path="/loginwithusername"
          component={LoginWithUsernamePassword}
        />
        <AuthorizedRoute
          path="/b2c/orders"
          TargetComponent={OrdersList}
          action={'orders:read'}
        />
        <AuthorizedRoute
          path="/pilots"
          TargetComponent={PilotsList}
          action={'pilots:read'}
        />
        <AuthorizedRoute
          path="/assets"
          TargetComponent={AssetsList}
          action={'assets:read'}
        />
        <AuthorizedRoute
          path="/home"
          TargetComponent={Home}
          action={'orders:read'}
        />
      </Switch>
    );
  }

  return renderDashboard();
}
export default AppRouter;
