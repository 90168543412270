import { FUEL_TYPES } from '../../components/SettingsPanel';
import { FUEL_CATEGORIES } from '../operations/supply/trucks/Model/Truck';

export const pageSize = 20;

export function isPetrolType(order_items: any) {
  return order_items.every((item: any) => {
    return (
      item.fuel_name === FUEL_TYPES['Super 98'] ||
      item.fuel_name === FUEL_TYPES['Special 95']
    );
  });
}
export function isDieselType(order_items: any) {
  return order_items.every((item: any) => {
    return item.fuel_name === FUEL_TYPES.Diesel;
  });
}
export function isBioDieselType(order_items: any) {
  return order_items.every((item: any) => {
    return item.fuel_name === FUEL_TYPES['B5-Biodiesel'];
  });
}
export const getFuelType = (order_items: any): FUEL_CATEGORIES | undefined => {
  if (isDieselType(order_items)) {
    return FUEL_CATEGORIES.DIESEL;
  } else if (isPetrolType(order_items)) {
    return FUEL_CATEGORIES.PETROL;
  } else if (isBioDieselType(order_items)) {
    return FUEL_CATEGORIES.BIODIESEL;
  }
  return undefined;
};

export const constructDetailedOrder = (data: any) => {
  return {
    address: data.job_address,
    completed_datetime: data.completed_datetime,
    completed_datetime_local: data.completed_datetime_local,
    customer_username: data.customer_username,
    fleet_id: data.fleet_id,
    fleet_name: data.fleet_name,
    fleet_thumb_image: data.fleet_image,
    fuel_name_count: '',
    is_scheduled: data.is_scheduled,
    job_delivery_datetime: data.job_delivery_datetime,
    job_id: data.job_id,
    job_pickup_datetime: data.job_pickup_datetime,
    job_pickup_name: data.job_pickup_name,
    job_status: data.job_status,
    job_time: data.job_time,
    job_type: data.job_type,
    latitude: data.job_latitude,
    longitude: data.job_longitude,
    no_of_vehicles: data.orderDetails.ITEMS.length,
    pickup_delivery_relationship: data.pickup_delivery_relationship,
    started_datetime: data.started_datetime,
    subscription_id: data.subscription_id,
    tags: data.tags,
    team_id: data.team_id,
    country_id: data.country_id,
  };
};
