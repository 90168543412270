import * as React from 'react';
import * as authHelper from '../helpers/auth';
import { Menu, Select, Dropdown } from 'antd';
import Cookies from 'universal-cookie';
import { SettingOutlined } from '@ant-design/icons';
import * as Utils from '../helpers/utils';
import { Link } from 'react-router-dom';
import { clearAccessCookies } from '../helpers/auth';
import { logout } from '../helpers/api';

const cookies = new Cookies();
const { Option } = Select;
export enum FUEL_TYPES {
  'Super 98' = 'Super 98',
  'Special 95' = 'Special 95',
  'Diesel' = 'Diesel',
  'B5-Biodiesel' = 'B5-Biodiesel',
}

export class SettingsPanel extends React.Component<any, any> {
  state = {
    visible: false,
  };

  async logout() {
    try {
      await logout();
      clearAccessCookies();
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  }

  handleMenuClick = (e: any) => {
    if (e.key === 'logout') {
      this.logout();
    }
  };
  handleVisibleChange = (flag: boolean) => {
    this.setState({ visible: flag });
  };

  isLoggedIn = () => {
    var accessToken = authHelper.getAccessToken();
    return accessToken && accessToken.length > 0;
  };
  render() {
    return this.isLoggedIn() ? (
      <Dropdown
        onVisibleChange={this.handleVisibleChange}
        visible={this.state.visible}
        overlay={
          <Menu onClick={this.handleMenuClick}>
            <Menu.Item key="logout" className={'logout-menu'}>
              Logout
            </Menu.Item>
          </Menu>
        }>
        <SettingOutlined className={'logout'} />
      </Dropdown>
    ) : (
      ''
    );
  }
}
