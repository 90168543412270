import React, { FC, useEffect, useState } from 'react';
import { DatePicker } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import styles from './styles.module.css';
import moment from 'moment';
const { RangePicker } = DatePicker;

interface SearchFiltersProps {
  updateDates: any;
  startDate: moment.Moment;
  endDate: moment.Moment;
}

const SearchFilters: FC<SearchFiltersProps> = ({
  startDate,
  endDate,
  updateDates,
}) => {
  const [selectedDateRange, setSelectedDateRange] = useState([
    startDate,
    endDate,
  ]);

  const handleDateChange = (dates: any, dateStrings: any) => {
    const [from, to] = dateStrings;
    if (from || to) {
      setSelectedDateRange([moment(from), moment(to)]);
    } else {
      setSelectedDateRange([]);
    }
  };

  useEffect(() => {
    updateDates(selectedDateRange[0], selectedDateRange[1]);
  }, [selectedDateRange]);

  return (
    <div className={styles.dropdownWrapper}>
      <RangePicker
        size={'middle'}
        allowClear={false}
        placeholder={['From', 'To']}
        format={'YYYY-MM-DD'}
        value={[selectedDateRange[0], selectedDateRange[1]]}
        ranges={{
          Today: [moment(), moment()],
          'This Week': [moment().startOf('week'), moment().endOf('week')],
          'This Month': [moment().startOf('month'), moment().endOf('month')],
        }}
        onChange={handleDateChange}
      />
      {selectedDateRange.length > 0 && (
        <a
          className={styles.closeDropdownBtn}
          onClick={() => handleDateChange(null, [])}>
          <CloseOutlined />
        </a>
      )}
    </div>
  );
};

export default SearchFilters;
