import React, { Fragment, useEffect, useState } from 'react';
import OrderItem from '../orderItem/OrderItem';
import { Alert } from '../../models/Alert';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Empty, Spin } from 'antd';
import { Order } from '../../models/Order';
interface OrdersListProps {
  jobs: Order[];
  onOrderItemSelected: (job: Order) => void;
  removeSelectedOrder?: (jobId: number) => void;
  selectedOrder?: Order[] | undefined;
  detailedOrder: number;
  loading?: boolean;
  alerts?: Array<Alert>;
}

const pageSize = 10;

function OrdersList(props: OrdersListProps) {
  const {
    jobs,
    onOrderItemSelected,
    selectedOrder,
    detailedOrder,
    removeSelectedOrder,
    loading = false,
  } = props;

  const [page, setPage] = useState(1);
  const [activeJobs, setActiveJobs] = useState<Order[]>([]);
  const fetchMoreData = () => {
    setPage(page + 1);
    setActiveJobs([
      ...activeJobs,
      ...jobs.slice(activeJobs.length, activeJobs.length + pageSize),
    ]);
  };

  useEffect(() => {
    setActiveJobs(jobs.slice(0, page * pageSize));
  }, [jobs]);

  return (
    <Fragment>
      {activeJobs.length > 0 ? (
        <Spin spinning={loading}>
          <ul
            style={{
              width: 'inherit',
              paddingLeft: '0px',
            }}>
            <InfiniteScroll
              dataLength={activeJobs.length}
              next={fetchMoreData}
              hasMore={activeJobs.length < jobs.length}
              height={'calc(100vh - 236px)'}
              loader={<h4>Loading...</h4>}>
              {activeJobs.map((job, index) => {
                return (
                  <li key={index}>
                    <OrderItem
                      detailedOrder={detailedOrder}
                      key={index}
                      onOrderSelected={(job: Order) => onOrderItemSelected(job)}
                      removeSelectedOrder={(jobId: number) =>
                        removeSelectedOrder && removeSelectedOrder(jobId)
                      }
                      job={job}
                      selectedOrder={selectedOrder}
                    />
                  </li>
                );
              })}
            </InfiniteScroll>
          </ul>
        </Spin>
      ) : (
        <Empty style={{ marginTop: '100%' }} />
      )}
    </Fragment>
  );
}

export default OrdersList;
