import * as React from 'react';

export interface MarketType {
  country: number;
  fuelType: number;
  refreshData: boolean;
}

const defaultContext: MarketType = {
  country: 1,
  fuelType: 1,
  refreshData: false,
};

const MarketContext = React.createContext<MarketType>(defaultContext);

export default MarketContext;
