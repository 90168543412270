import React, { ReactText, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import applicationLayout from '../../components/layout/Layout';
import BreadCrumbs from '../../components/layout/BreadCrumbs/BreadCrumbs';
import { message, Table, Modal } from 'antd';
import Search from 'antd/lib/input/Search';
import { getDataTableColumns } from './utils';
import styles from './styles.module.css';
import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Pilot } from '../../models/Pilots';
import ManagePilotModal from './ManagePilotModal';
import { createPilot, editPilot, getPilotsList } from '../../helpers/api';

const { confirm } = Modal;

const OrdersList = () => {
  const [pilotsList, setPilotsList] = useState<Pilot[]>([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [isManagePilotModalOpen, setIsManagePilotModalOpen] = useState(false);
  const [pilotToEdit, setPilotToEdit] = useState<Pilot>();

  useEffect(() => {
    fetchPilots();
  }, []);

  useEffect(() => {
    setIsManagePilotModalOpen(Boolean(pilotToEdit));
  }, [pilotToEdit]);

  const fetchPilots = async () => {
    try {
      setIsLoadingData(true);
      const response = await getPilotsList();
      setPilotsList(response?.data?.data ?? []);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingData(false);
    }
  };

  const filterPilots = (pilots: Pilot[], filter: string): Pilot[] => {
    return pilots.filter((pilot: Pilot) => {
      filter = filter.toLowerCase();
      return (
        pilot.code?.toLowerCase().includes(filter) ||
        pilot.first_name?.toLowerCase().includes(filter) ||
        pilot.last_name?.toLowerCase().includes(filter) ||
        pilot.phone_number.toString().includes(filter)
      );
    });
  };

  const handleAdd = async (payload: any) => {
    try {
      await createPilot({
        ...payload,
        active: true,
      });
      message.success(
        `Pilot ${payload.first_name} ${payload.last_name} is added successfully`
      );
      fetchPilots();
      setIsManagePilotModalOpen(false);
    } catch (error: any) {
      message.error(error?.message || error);
    }
  };

  const handleEdit = async (payload: any) => {
    const pilotId = pilotToEdit?.id;
    if (!pilotId) return;

    try {
      await editPilot(pilotId, {
        ...payload,
        active: pilotToEdit?.active,
      });
      message.success(
        `Data of pilot ${payload.first_name} ${payload.last_name} is updated successfully`
      );
      fetchPilots();
      setIsManagePilotModalOpen(false);
      setPilotToEdit(undefined);
    } catch (error: any) {
      message.error(error.message || error);
    }
  };

  const activatePilot = (pilot: Pilot) => {
    confirm({
      title: 'Reactivate Pilot',
      content: `Are you sure you want to activate ${pilot.first_name} ${pilot.last_name}?`,
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        try {
          await editPilot(pilot.id, {
            first_name: pilot.first_name,
            last_name: pilot.last_name,
            phone_number: pilot.phone_number,
            active: true,
          });
          message.success(
            `Pilot ${pilot.first_name} ${pilot.last_name} is activated successfully`
          );
          fetchPilots();
        } catch (error: any) {
          message.error(error.message || error);
        }
      },
      onCancel() {},
    });
  };

  const inactivatePilot = (pilot: Pilot) => {
    confirm({
      title: 'In-activate Pilot',
      content: `Are you sure you want to inactivate ${pilot.first_name} ${pilot.last_name}?`,
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        try {
          await editPilot(pilot.id, {
            first_name: pilot.first_name,
            last_name: pilot.last_name,
            phone_number: pilot.phone_number,
            active: false,
          });
          message.success(
            `Pilot ${pilot.first_name} ${pilot.last_name} is inactivated successfully`
          );
          fetchPilots();
        } catch (error: any) {
          message.error(error.message || error);
        }
      },
      onCancel() {},
    });
  };

  const handleActionClick = (key: ReactText, pilot: Pilot) => {
    switch (key) {
      case 'edit':
        setPilotToEdit(pilot);
        break;
      case 'inactivate':
        inactivatePilot(pilot);
        break;
      case 'activate':
        activatePilot(pilot);
        break;
      default:
        break;
    }
  };

  return (
    <main className={styles.content}>
      <BreadCrumbs items={[<Link to="/home">Dashboard</Link>, 'Pilots']} />
      <div className={styles.headerGrid}>
        <div className={styles.pageTitle}>Pilots</div>
        <button
          className={styles.addPilotBtn}
          onClick={() => setIsManagePilotModalOpen(true)}>
          Add New Pilot
        </button>
      </div>
      <div className={styles.searchWrapper}>
        <Search
          value={searchString}
          onChange={e => setSearchString(e.target.value)}
          size="large"
          placeholder="Search by pilot name or phone..."
          enterButton
          suffix={
            <CloseOutlined
              onClick={() => {
                setSearchString('');
              }}
            />
          }
        />
      </div>

      <Table
        dataSource={filterPilots(pilotsList, searchString)}
        columns={getDataTableColumns(handleActionClick)}
        rowKey="id"
        rowClassName={styles.pilotsTable__row}
        loading={isLoadingData}
      />

      {(isManagePilotModalOpen || pilotToEdit) && (
        <ManagePilotModal
          onSubmit={(data: any) =>
            Boolean(pilotToEdit) ? handleEdit(data) : handleAdd(data)
          }
          onCancel={() => {
            setIsManagePilotModalOpen(false);
            setPilotToEdit(undefined);
          }}
          editData={pilotToEdit}
        />
      )}
    </main>
  );
};

export default applicationLayout(OrdersList);
