import * as React from 'react';
import styles from './map-popover.module.css';
import { Row, Col } from 'antd';
import * as dateTimeHelper from '../../../helpers/dateTimeHelper';
import { FuelLabelCircle } from '../../fuelLabel/FuelLabel';

interface OrderMapPopOverProps {
  hoveredFeaturePosition: Array<number>;
  hoveredFeature: GeoJSON.Feature;
}
function OrderMapPopOver(props: OrderMapPopOverProps) {
  const {
    hoveredFeature: hoveredOrder,
    hoveredFeaturePosition: hoveredOrderPosition,
  } = props;
  return (
    <div
      className={styles.tooltip}
      style={{
        left: hoveredOrderPosition[0],
        top: hoveredOrderPosition[1],
      }}>
      <h5>Order</h5>
      <h4>{hoveredOrder!.id}</h4>
      <h4 className={styles.orderTag}>{hoveredOrder!.properties!.fleet}</h4>
      <Row justify="space-between">
        <Col>
          <span className={styles.slot}>
            {`
            ${dateTimeHelper.convertDateTimeToCurrentZone(
              hoveredOrder!.properties!.start_time,
              ''
            )} 
            - ${dateTimeHelper.convertDateTimeToCurrentZone(
              hoveredOrder!.properties!.end_time,
              ''
            )}
          `}
          </span>
        </Col>
      </Row>
      {/* <div style={{ padding: '8px 0 0 0' }}>
        {hoveredOrder!.properties!.fuel_count &&
          Object.keys(JSON.parse(hoveredOrder!.properties!.fuel_count)).map(
            (fuel, index) => {
              return (
                <FuelLabelCircle
                  key={index}
                  vehicles={
                    JSON.parse(hoveredOrder!.properties!.fuel_count)[fuel]
                  }
                  fuel={fuel}
                />
              );
            }
          )}
      </div> */}
    </div>
  );
}

export function TruckMapPopOver(props: OrderMapPopOverProps) {
  const {
    hoveredFeature: hoveredTruck,
    hoveredFeaturePosition: hoveredTruckPosition,
  } = props;
  return (
    <div
      className={styles.tooltip}
      style={{
        left: hoveredTruckPosition[0],
        top: hoveredTruckPosition[1],
      }}>
      <h5>Truck</h5>
      <h4>{hoveredTruck!.properties!.modelYear}</h4>
      <h4 className={styles.orderTag}>{hoveredTruck!.properties!.zone}</h4>
      <h4 className={styles.orderTag}>{hoveredTruck!.properties!.license}</h4>
      <h4 className={styles.orderTag}>{`${hoveredTruck!.properties!.code}`}</h4>
      <h4 className={styles.orderTag}>{hoveredTruck!.properties!.phone}</h4>
      <h4 className={styles.orderTag}>
        {hoveredTruck!.properties!.stateOfCharge} /{' '}
        {hoveredTruck!.properties!.capacity}
      </h4>
      {/* <div style={{ padding: '8px 0 0 0' }}>
        {hoveredTruck!.properties!.type && (
          <FuelLabelCircle
            vehicles={undefined}
            fuel={
              hoveredTruck!.properties!.fuel_type[0].toUpperCase() +
              hoveredTruck!.properties!.fuel_type.slice(1).toLowerCase()
            }
          />
        )}
      </div> */}
    </div>
  );
}

interface PopUpProps {
  popUpFeature: GeoJSON.Feature | undefined;
}
export function OrderPopUp(props: PopUpProps) {
  const { popUpFeature } = props;
  return (
    <div style={{ padding: '8px' }}>
      <span className={styles.popUpPrimarySmall}>
        {popUpFeature!.properties!.fleet}
      </span>
      <br />
      <span className={styles.popUpPrimary}>{popUpFeature!.id}</span>
      <br />

      <span className={styles.popUpSecondary}>
        {popUpFeature!.properties!.customer}
      </span>
    </div>
  );
}
export function AlertPopUp(props: PopUpProps) {
  const { popUpFeature } = props;
  return (
    <div>
      <span className={styles.popUpPrimary}>{popUpFeature!.id}</span>
      <br></br>
      <span className={styles.popUpSecondary}>
        {popUpFeature!.properties!.customer}
      </span>
    </div>
  );
}
export function FleetPopUp(props: PopUpProps) {
  const { popUpFeature } = props;
  return (
    <div className="orderPopUp">
      <span className={styles.popUpPrimary}>
        {popUpFeature!.properties!.fleet_name}
      </span>
      <br></br>
      <span className={styles.popUpSecondary}>
        {popUpFeature!.properties!.license}
      </span>
    </div>
  );
}

export function OrderEventPopUp(props: PopUpProps) {
  const { popUpFeature } = props;
  return (
    <div className="orderEventPopUp">
      <span className={styles.popUpPrimary}>
        {popUpFeature!.properties!.fleet_name}
      </span>
      <br></br>
      <span className={styles.popUpSecondary}>{'Event'}</span>
    </div>
  );
}

export function DefaultPopUp(props: PopUpProps) {
  const { popUpFeature } = props;
  return (
    <div className="orderPopUp">
      <span className={styles.popUpPrimary}>{popUpFeature!.id}</span>
      <br></br>
      <span className={styles.popUpSecondary}>
        {popUpFeature!.properties!.type}
      </span>
    </div>
  );
}

export default React.memo(OrderMapPopOver);
