import * as Utils from '../helpers/utils';

export const JobStatus: xStatus = {
  created: 'created',
  unpaid: 'unpaid',
  placed: 'placed',
  in_progress: 'in_progress',
  unassigned: 'unassigned',
  assigned: 'assigned',
  on_the_way: 'on_the_way',
  arrived: 'arrived',
  completed: 'completed',
  cancelled: 'cancelled',
};

export const JobStatusToID = {
  created: 1,
  unpaid: 2,
  placed: 3,
  in_progress: 4,
  unassigned: 5,
  assigned: 6,
  on_the_way: 7,
  arrived: 8,
  completed: 9,
  cancelled: 10,
};

interface xStatus {
  [x: string]: string;
}
export const JobStausColor: xStatus = {
  created: '#2196F3',
  unpaid: '#3F51B5',
  placed: '#BA68C8',
  in_progress: '#BA68C8',
  unassigned: '#999999',
  assigned: '#757D8A',
  on_the_way: '#2196F3',
  arrived: '#3F51B5',
  completed: '#3F51B5',
  cancelled: '#999999',
};

export const isValidJobStatus = (job_status: string) => {
  if (Utils.isUnassignedJob(job_status)) return true;
  return [
    JobStatus.in_progress,
    JobStatus.arrived,
    JobStatus.on_the_way,
  ].includes(job_status);
};

export const isActiveJobStatus = (status: string) => {
  return [
    JobStatus.placed,
    JobStatus.assigned,
    JobStatus.on_the_way,
    JobStatus.arrived,
  ].includes(status);
};
