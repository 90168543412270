import { JobStatus } from '../models/JobStatus';
import { TimeSlot } from '../models/TimeSlot';
import { Moment } from 'moment';
import moment from 'moment';
import * as dateTimeHelper from '../helpers/dateTimeHelper';
import { Job } from '../models/Task';
import { FUEL_TYPES } from '../components/SettingsPanel';
import RRule, { Weekday } from 'rrule';
import * as authHelper from '../helpers/auth';

import {
  MIDNIGHT_ORDERS_END_TIME,
  MIDNIGHT_ORDERS_START_TIME,
  NIGHTLY_ORDER_START_HOUR,
  NIGHTLY_ORDER_END_HOUR,
} from './app-constants';
import { FUEL_CATEGORIES } from '../domains/operations/supply/trucks/Model/Truck';
import { Order, OrderStatusTypes } from '../models/Order';

const HOURS_IN_DAY = 24;

/**
 * Check if status change option is appicable for selected order
 * @param status number
 */
export function isStatusChangeAvailable(status: OrderStatusTypes): boolean {
  let isAvailable = false;
  switch (status) {
    case 'placed':
    case 'on_the_way':
    case 'arrived':
    case 'assigned':
      isAvailable = true;
      break;

    default:
      isAvailable = false;
      break;
  }

  return isAvailable;
}

export enum FuelTypeMap {
  'Diesel' = 'DIESEL',
  'Super 98' = 'PETROL',
  'Special 95' = 'PETROL',
  'B5-Biodiesel' = 'BIODIESEL',
}

export const isPetrol = (fuel_name_count: any) => {
  return Object.keys(fuel_name_count).every((fuel: string) =>
    (FuelTypeMap as any)[fuel]
      ? (FuelTypeMap as any)[fuel].localeCompare('PETROL') === 0
      : false
  );
};

export function isUnassignedJob(status: string): boolean {
  return [JobStatus.unassigned].includes(status);
}

export function getSelectedCountry(): number {
  let data = localStorage.getItem('selectedCountry');
  return data != null ? parseInt(data) : 1;
}

export function getCountryPhoneCode(): string | undefined {
  try {
    const countryId = getSelectedCountry();
    const countriesString = localStorage.getItem('countries');
    if (countriesString) {
      const parsedCountries = JSON.parse(countriesString);
      const selectedCountry = parsedCountries.find(
        (country: any) => country.country_id === countryId
      );
      return selectedCountry?.country_code;
    }
  } catch (error) {
    console.error(error);
  }
}

export function setSelectedCountry(selectedCountryID: number) {
  localStorage.setItem('selectedCountry', selectedCountryID.toString());
}

export function getCountryCurrency(): string | undefined {
  try {
    const countryId = getSelectedCountry();
    const countriesString = localStorage.getItem('countries');
    if (countriesString) {
      const parsedCountries = JSON.parse(countriesString);
      const selectedCountry = parsedCountries.find(
        (country: any) => country.country_id === countryId
      );
      return selectedCountry?.currency;
    }
  } catch (error) {
    console.error(error);
  }
}

export function getSelectedFuelType(): number {
  let data = localStorage.getItem('selectedFuelType');
  return data != null ? parseInt(data) : 0;
}

export function setSelectedFuelType(selectedFuelType: number) {
  localStorage.setItem('selectedFuelType', selectedFuelType.toString());
}
export function getSelectedMenu(): string {
  let data = localStorage.getItem('selectedMenu');
  return data != null ? data : 'home';
}

export function setSelectedMenu(selectedMenu: string) {
  localStorage.setItem('selectedMenu', selectedMenu);
}

export function getSelectedTeams(): number[] {
  let data = localStorage.getItem('selectedTeams');
  return data != null && data.length > 0
    ? data.split(',').map(team => parseInt(team))
    : [];
}

export function setSelectedTeams(selectedTeams: number[]) {
  localStorage.setItem('selectedTeams', selectedTeams.join());
}
export function getTimeSlots() {
  const today = dateTimeHelper.getTodaysDate();
  let slots: TimeSlot[] = Array.from(Array(24).keys()).map(
    (startValue: number) => {
      return {
        id: startValue,
        displayValue: `${startValue}:00 - ${startValue + 1}:00`,
        startTimeEpoch: Date.parse(`${today} ${startValue}:00`),
        endTimeEpoch: Date.parse(`${today} ${startValue + 1}:00`),
      };
    }
  );
  return slots;
}

export function isPetrolType(job: Job) {
  return Object.keys(job.fuel_name_count).every(fuel => {
    return fuel === FUEL_TYPES['Super 98'] || fuel === FUEL_TYPES['Special 95'];
  });
}
export function isDieselType(job: Job) {
  return Object.keys(job.fuel_name_count).every(fuel => {
    return fuel === FUEL_TYPES.Diesel;
  });
}
export function isBioDieselType(job: Job) {
  return Object.keys(job.fuel_name_count).every(fuel => {
    return fuel === FUEL_TYPES['B5-Biodiesel'];
  });
}
export const getFuelType = (job: Job): FUEL_CATEGORIES | undefined => {
  if (isDieselType(job)) {
    return FUEL_CATEGORIES.DIESEL;
  } else if (isPetrolType(job)) {
    return FUEL_CATEGORIES.PETROL;
  } else if (isBioDieselType(job)) {
    return FUEL_CATEGORIES.BIODIESEL;
  }
  return undefined;
};

/**
 * Checks if a product type is a Petrol
 * @param {string} product Product.ts name, Special 95, Super 98 ...
 * @returns {boolean}
 */
export function isPetrolProduct(product: string) {
  return (
    product === FUEL_TYPES['Super 98'] || product === FUEL_TYPES['Special 95']
  );
}
/**
 * Checks if a product type is a Diesel
 * @param {string} product Product.ts name, Special 95, Super 98 ...
 * @returns {boolean}
 */
export function isDieselProduct(product: string) {
  return product === FUEL_TYPES.Diesel;
}

/**
 * Checks if a product type is a BioDiesel
 * @param {string} product Product.ts name, Special 95, Super 98 ...
 * @returns {boolean}
 */
export function isBioDieselProduct(product: string) {
  return product === FUEL_TYPES['B5-Biodiesel'];
}

/**
 * Fetch product category based of product name
 * @param { string } product product name
 * @returns {string|undefined} FUEL_CATEGORIES
 */
export const getProductCategory = (
  product: string
): FUEL_CATEGORIES | undefined => {
  if (isDieselProduct(product)) {
    return FUEL_CATEGORIES.DIESEL;
  } else if (isPetrolProduct(product)) {
    return FUEL_CATEGORIES.PETROL;
  } else if (isBioDieselProduct(product)) {
    return FUEL_CATEGORIES.BIODIESEL;
  }
  return undefined;
};

/**
 * Validates if the collection has jobs of multiple fuel types
 * @param {Array<Job>} jobs Array of jobs
 * @returns {boolean}
 */
export function isMixedFuelType(jobs: Array<Job>) {
  const isOrdersPetrolType = jobs.some(isPetrolType);
  const isOrdersDieselType = jobs.some(isDieselType);
  const isOrdersBioDieselType = jobs.some(isBioDieselType);

  //src : https://stackoverflow.com/questions/53891337/check-to-see-if-exactly-two-out-of-three-booleans-are-true
  const isMixed =
    [isOrdersPetrolType, isOrdersDieselType, isOrdersBioDieselType].filter(
      Boolean
    ).length > 1;
  return isMixed;
}

/**
 * Get start date to set RRULE dstart, time is set to 00:00:00
 * @param date Moment
 */
export function getUTCDate(date: Moment, year: number) {
  return new Date();
  // Date.UTC(date.year() + year, date.month(), date.date(), 0, 0)
}

/**
 * Get Days of a week accorsing to RRUle format
 */
export const rRuleDays: Array<Weekday> = [
  RRule.SU,
  RRule.MO,
  RRule.TU,
  RRule.WE,
  RRule.TH,
  RRule.FR,
  RRule.SA,
];
export const getMomentIsoString = (date: Moment | undefined): string => {
  return moment(date).set({ second: 0, millisecond: 0 }).toISOString(true);
};

/**
 * Compute duration b/w dates in milliseconds
 * @param start Start date : Moment
 * @param end End date : Moment
 */
export const getMomentDuration = (start: Moment, end: Moment): number => {
  let duration = moment(end).diff(moment(start));
  return duration;
};

export const getDisabledHours = (
  startTime: Moment,
  endTime: Moment
): Array<number> => {
  let hours = [];
  for (let i = 0; i < 24; i++) {
    if (i < moment(startTime).hour() || i > moment(endTime).hour()) {
      hours.push(i);
    }
  }
  return hours;
};

export function capitalizeFirstAlphabet(string: string) {
  return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
}

/**
 * Get key of a json object by val
 * @param object Object<T>
 * @param value string
 */
export function getKeyByValue(object: any, value: string) {
  return Object.keys(object).find(key => object[key] === value);
}

/**
 * Download JSON data to a file
 * @param data JSON data to be downloaded
 * @param filename
 * source : https://stackoverflow.com/questions/19721439/download-json-object-as-a-file-from-browser
 */
export const downloadJsonFile = (data: any, filename: string) => {
  // Creating a blob object from non-blob data using the Blob constructor
  const blob = new Blob([JSON.stringify(data)], {
    type: 'application/json',
  });
  const url = URL.createObjectURL(blob);
  // Create a new anchor element
  const a = document.createElement('a');
  a.href = url;
  a.download = filename || 'download';
  a.click();
  a.remove();
};

/**
 * Formats ISO string to Date in the fromat of DD/MM/YYYY HH:mm a with timezone offset
 * @param datetime ISO datetime string
 * @param offset Timezone offet in minutes
 */
export function formatISOtoDateTime(
  datetime: string,
  offset: number,
  format: string
) {
  return moment(datetime).utcOffset(offset).format(format);
}

interface MidnightOrderFromTo {
  from: string;
  to: string;
}

export const getMidnightOrdersDateRange = (
  country_id: number,
  selectedDate: Moment
): MidnightOrderFromTo | null => {
  const timezone = authHelper.getTimezone(country_id);
  let currentTime = moment().tz(timezone);
  let start_of_the_day = currentTime.clone().startOf('day');
  let end_of_the_day = currentTime.clone().endOf('day');
  let format = 'hh:mm:ss';
  let midnight_orders_start_time = moment(
    MIDNIGHT_ORDERS_START_TIME,
    format
  ).tz(timezone);
  let midnight_orders_end_time = moment(MIDNIGHT_ORDERS_END_TIME, format).tz(
    timezone
  );

  /**
   *  Display midnight orders only for current day.
   */
  if (
    !moment(selectedDate)
      .startOf('day')
      .isSame(moment(currentTime).startOf('day'))
  ) {
    return null;
  }
  if (currentTime.isBetween(midnight_orders_start_time, end_of_the_day)) {
    return {
      from: start_of_the_day.format(),
      to: midnight_orders_end_time.clone().add(1, 'day').format(),
    };
  } else if (
    currentTime.isBetween(midnight_orders_end_time, start_of_the_day)
  ) {
    return {
      from: start_of_the_day.clone().subtract(1, 'day').format(),
      to: midnight_orders_end_time.format(),
    };
  } else {
    return null;
  }
};

export const isNightOrder = (startTime: string) => {
  const hour = parseInt(moment(startTime).format('HH'));
  // Order between 10PM to 7AM are considered as nightly orders
  return hour >= NIGHTLY_ORDER_START_HOUR || hour <= NIGHTLY_ORDER_END_HOUR;
};

/**
 * Given an order, returns number of hours relative to pickup time.
 * @param order Job
 * @returns hour relative to pickup time
 */
export const getRelativeDeliveryTime = (order: Order) => {
  const timezoneOffset = new Date().getTimezoneOffset() / 60;
  const pickup_date_time = new Date(order.timeslot.begins_at);
  const delivery_date_time = new Date(order.timeslot.ends_at);
  const date_diff = Math.abs(
    pickup_date_time.getDate() - delivery_date_time.getDate()
  );
  const relative_delivery_hour =
    date_diff * HOURS_IN_DAY +
    parseInt(order?.timeslot?.ends_at?.split('T')[1]?.split(':')[0] ?? 0) -
    timezoneOffset;

  return relative_delivery_hour;
};
