import React from 'react';
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Form, Input, Button, message } from 'antd';
import '../css/LoginWithUsernamePassword.css';
import { login } from '../helpers/api';
import { clearAccessCookies, updateAccessCookies } from '../helpers/auth';
import { REACT_APP_MENA_SERVER_API_HOSTNAME } from '../helpers/app-constants';

const jw_ser_url = `${REACT_APP_MENA_SERVER_API_HOSTNAME}`;

export class LoginWithUsernamePassowrd extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      redirectToReferrer: false,
      username: '',
      pwd: '',
      isResetPassword: false,
      loading: false,
    };
    this.handleUserName = this.handleUserName.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }
  handleUserName(event: any) {
    this.setState({ username: event.target.value });
  }
  handlePassword(event: any) {
    this.setState({ pwd: event.target.value });
  }
  async handleSubmit(event: any) {
    event.preventDefault();
    const { history } = this.props;
    try {
      this.setState({ loading: true });
      const response: any = await login({
        username: this.state.username,
        password: this.state.pwd,
      });
      const { token, refresh_token } = response.data;
      updateAccessCookies({ token, refreshToken: refresh_token });
      history.push('/');
    } catch (error) {
      clearAccessCookies();
    } finally {
      this.setState({ loading: false });
    }
  }
  handleReset(event: any) {
    event.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

    var urlencoded = new URLSearchParams();
    urlencoded.append('email', this.state.username);
    var requestOptions: any = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow',
    };

    fetch(jw_ser_url + '/users_forgot_password_from_email', requestOptions)
      .then(response => response.text())
      .then(result => {
        let response = JSON.parse(result);
        if (response.status === 200) {
          message.success('Please check your email for reset instructions');
        } else {
          message.error(response.message);
        }
      })
      .catch(error => console.log('error', error));
  }

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/' } };
    const { redirectToReferrer, isResetPassword } = this.state;

    if (redirectToReferrer === true) {
      return <Redirect to={from} />;
    }

    return (
      <div style={{ background: '#e5e5e5', height: '100vh', display: 'flex' }}>
        <div style={{ background: '#ffffff', width: '594px', margin: 'auto' }}>
          <img className="login-logo" alt="cafu-logo" src="login-logo.svg" />
          <Form
            name="login"
            layout={'vertical'}
            className="login-form"
            initialValues={{
              remember: true,
            }}>
            {isResetPassword && (
              <>
                <Form.Item>
                  <span className="login-title">
                    Enter your email to receive instructions on how to reset
                    your password.
                  </span>
                </Form.Item>
              </>
            )}
            <Form.Item
              label={<span className="login-title">Email address</span>}
              name="username"
              rules={[
                {
                  required: true,
                  message: 'Please input your Email address!',
                },
                {
                  type: 'email',
                  message: 'The input is not valid Email!',
                },
              ]}>
              <Input
                className={'login-input'}
                size="large"
                name="username"
                value={this.state.username}
                onChange={this.handleUserName}
              />
            </Form.Item>

            {!isResetPassword && (
              <>
                <Form.Item
                  label={<span className="login-title">Password</span>}
                  name="password"
                  rules={[
                    { required: true, message: 'Please input your password!' },
                  ]}>
                  <Input.Password
                    className={'login-input'}
                    size="large"
                    value={this.state.pwd}
                    onChange={this.handlePassword}
                  />
                </Form.Item>
              </>
            )}

            {isResetPassword ? (
              <>
                <Form.Item style={{ marginTop: '32px', marginBottom: '8px' }}>
                  <Button
                    type="primary"
                    onClick={this.handleReset}
                    htmlType="submit"
                    className="login-form-button">
                    Reset password
                  </Button>
                </Form.Item>
                <Form.Item
                  className={'login-form-forgot'}
                  style={{ marginBottom: '48px' }}>
                  <span
                    onClick={() => this.setState({ isResetPassword: false })}>
                    Back to login
                  </span>
                </Form.Item>
              </>
            ) : (
              <Form.Item style={{ marginBottom: '48px' }}>
                <Button
                  type="primary"
                  onClick={this.handleSubmit}
                  htmlType="submit"
                  className="login-form-button"
                  disabled={this.state.loading}>
                  Log in
                </Button>
              </Form.Item>
            )}
          </Form>
        </div>
      </div>
    );
  }
}

export default withRouter(LoginWithUsernamePassowrd);
