import React from 'react';
import { Modal, Form, Input } from 'antd';
import styles from './styles.module.css';
import { Pilot } from '../../models/Pilots';

interface ComponentProps {
  onCancel: Function;
  onSubmit: (editData: Pilot) => {};
  editData?: Pilot;
}

const ManagePilotModal = ({ onCancel, onSubmit, editData }: ComponentProps) => {
  const isEdit = Boolean(editData);
  const [form] = Form.useForm();

  return (
    <Modal
      title={
        isEdit
          ? `Edit Pilot: ${editData?.first_name} ${editData?.last_name}`
          : 'Add Pilot'
      }
      visible={true}
      onOk={() => {
        form.submit();
      }}
      onCancel={() => onCancel(false)}>
      <Form
        form={form}
        name="managePilot"
        layout="vertical"
        initialValues={{
          ...editData,
        }}
        className={styles.managePilot__form}
        onFinish={() => onSubmit(form.getFieldsValue())}>
        <Form.Item
          label="First Name"
          name="first_name"
          hasFeedback
          rules={[
            { required: true, message: 'Please input your first name' },
            {
              pattern: /^[A-Za-z]+$/,
              message: 'Please enter a valid first name',
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="last_name"
          hasFeedback
          rules={[
            { required: true, message: 'Please input your last name' },
            {
              pattern: /^[A-Za-z]+$/,
              message: 'Please enter a valid last name',
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Phone Number"
          name="phone_number"
          hasFeedback
          rules={[
            { required: true, message: 'Please input your phone number' },
            { min: 8, message: 'Number should have 8 characters or more' },
            {
              pattern: /^[\+][0-9]+$/,
              message: 'Please enter a valid phone number',
            },
          ]}>
          <Input type="text" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ManagePilotModal;
