interface FuelCompartmentsDetail {
  capacity: number;
  fleet_id: number;
  fuel_id: number;
  fuel_left: number;
  name: string;
  tcs_fuel_left: number;
}
export interface Truck {
  color: string;
  country_id: number;
  creation_datetime: string;
  first_name: string;
  fleet_id: number;
  fleet_image: string;
  fleet_thumb_image: string;
  has_gps_accuracy: number;
  is_active: number;
  is_available: number;
  is_deleted: number;
  license: string;
  model: string;
  registration_status: number;
  service_id: number;
  tb_fleet_team_id: number;
  team_id: number;
  team_name: string;
  timezone: string;
  total_capacity: number;
  transport_type: number;
  type: string;
  passes: Pass[];
  updation_datetime: string;
  year: string;
  fuel_details: Array<FuelCompartmentsDetail>;
}

export interface Pass {
  id: number;
  pass_name: string;
  region_id: number;
  description: string;
  created_at: string;
  updated_at: string;
}

export function filterTruck(truck: Truck, filter: string): boolean {
  filter = filter.toLowerCase();
  return (
    truck.fleet_id.toString().includes(filter) ||
    truck.license?.toLowerCase().includes(filter) ||
    truck.model?.toLowerCase().includes(filter) ||
    truck.first_name?.toLowerCase().includes(filter)
  );
}
export function filterPass(pass: Pass, filter: string): boolean {
  filter = filter.toLowerCase();
  return (
    pass.pass_name?.toLowerCase().includes(filter) ||
    pass.id.toString().includes(filter)
  );
}

export enum FUEL_CATEGORIES {
  PETROL = 'PETROL',
  DIESEL = 'DIESEL',
  BIODIESEL = 'BIODIESEL',
}
export interface FuelCompartments {
  fuel_id: number;
  fuel_capacity: number;
}
export interface TruckParams {
  timezone: number;
  access_token: string;
  team_ids: number;
  first_name: string;
  color: string;
  license: string;
  year: string;
  model: string;
  total_capacity: number;
  transport_type: number;
  type: FUEL_CATEGORIES;
  fleet_image: any;
  can_view: number;
  can_edit: number;
  create_signup_fleet: number;
  fuel_details: Array<FuelCompartments>;
  country_id: number;
  // shift_array: [{"shift_start_time":"01:00","shift_end_time":"19:00","break_start_time":"08:00","break_end_time":"10:00"}]
}

export const dummyTruck: TruckParams = {
  timezone: -240,
  access_token: '152b61d0a51d4c031c152d3a1c13224e18e3c1f922',
  team_ids: 23,
  first_name: 'DH123123098',
  color: 'Grey',
  license: 'W2487',
  year: '2020',
  model: 'Hino',
  total_capacity: 2000,
  transport_type: 6,
  type: FUEL_CATEGORIES.DIESEL,
  fleet_image: '(binary)',
  can_view: 1,
  can_edit: 0,
  create_signup_fleet: 0,
  fuel_details: [
    { fuel_id: 1, fuel_capacity: 600 },
    { fuel_id: 2, fuel_capacity: 1400 },
    { fuel_id: 15, fuel_capacity: 0 },
  ],
  country_id: 1,
};

export interface FuelCompartment {
  fuel_id: number;
  fuel_capacity: number;
}

interface ScheduleTimeBox {
  start: string;
  duration: number;
}
interface ScheduleRecurrence {
  rrule: string;
  since: string;
  until?: string;
}
interface SchedulePayload {
  type: string;
  pilot?: string;
}

export interface Schedule {
  timeBox: ScheduleTimeBox;
  recurrence: ScheduleRecurrence;
  payload: SchedulePayload;
}
export default interface TruckPayload {
  name: string;
  year: number;
  model: string;
  team_id: number;
  colour: string;
  license_plate: string;
  type: FUEL_CATEGORIES;
  capacity: number;
  fuel_details: Array<FuelCompartment>;
  schedule?: {
    timeBox: ScheduleTimeBox;
    recurrence: ScheduleRecurrence;
    payload: { type: string };
  };
  country_id: number;
  schedules: Array<Schedule>;
}
export interface UpdateTruckPayload {
  asset_id: number;
  schedules: Array<Schedule>;
}
export const dummySchedule: Schedule = {
  timeBox: {
    start: '0',
    duration: 0,
  },
  recurrence: {
    rrule: '',
    since: '',
  },
  payload: {
    type: 'B2B',
  },
};
export const dummyTruckPayload: TruckPayload = {
  team_id: 0,
  name: '',
  colour: 'Grey',
  license_plate: '',
  year: 2020,
  model: 'Hino',
  capacity: 2000,
  type: FUEL_CATEGORIES.PETROL,
  fuel_details: [
    { fuel_id: 1, fuel_capacity: 0 },
    { fuel_id: 2, fuel_capacity: 0 },
    { fuel_id: 15, fuel_capacity: 0 },
  ],
  country_id: 1,
  schedules: [],
};
export interface ProductType {
  product_id: number;
  name: string;
}

export interface EditTruckPayload {
  first_name: string;
  fleet_id: number;
  access_token: string;
  year: number;
  model: string;
  team_ids: number;
  color: string;
  license: string;
  type: FUEL_CATEGORIES;
  total_capacity: number;
  transport_type: number;
  fuel_details: Array<FuelCompartment>;
  timezone: number;
}

export const dummyEditTruck: EditTruckPayload = {
  fleet_id: 193,
  access_token: '152b61d0a51d4c031c152d3a1c13224e18e3c1f922',
  team_ids: 10,
  timezone: -240,
  first_name: 'Truck 0000987',
  color: 'Grey',
  license: 'w000987',
  year: 2020,
  model: 'Hino',
  total_capacity: 2000,
  type: FUEL_CATEGORIES.PETROL,
  transport_type: 6,
  fuel_details: [
    { fuel_id: 1, fuel_capacity: 600 },
    { fuel_id: 2, fuel_capacity: 1400 },
    { fuel_id: 15, fuel_capacity: 0 },
  ],
};
