import * as React from 'react';
import { Layout } from 'antd';

const { Header, Footer, Sider, Content } = Layout;

interface SidePanelProps {}
function SidePanel(props: React.PropsWithChildren<SidePanelProps>) {
  return (
    <Sider
      theme="light"
      width="294px"
      style={{
        overflow: 'auto',
        height: 'calc(100vh-56)',
        background: '#F5F5F5',
      }}
    >
      {props.children}
    </Sider>
  );
}

export default SidePanel;
