import React from 'react';
import { Modal, Select, message, Button } from 'antd';
import { JobStatus } from '../models/JobStatus';
import styles from '../css/AlertListView.module.css';
import { CancelOrderRequest, OrderStatusTypes } from '../models/Order';
import { cancelOrderWithReason, updateOrderStatus } from '../helpers/api';

const { Option } = Select;

const CANCEL_REASONS: CancelOrderRequest[] = [
  {
    reason: 'Vehicle is not available at the pin location',
    reason_id: 'cancellation_reason_1',
  },
  {
    reason: 'Charging cap is closed',
    reason_id: 'cancellation_reason_2',
  },
  {
    reason: 'Vehicle in unaccessible',
    reason_id: 'cancellation_reason_3',
  },
  {
    reason: 'Vehicle parked in an unsafe area',
    reason_id: 'cancellation_reason_4',
  },
  {
    reason: 'Unable to reach the location',
    reason_id: 'cancellation_reason_5',
  },
  {
    reason: 'Order canceled/asked by the customer to cancel',
    reason_id: 'cancellation_reason_6',
  },
  {
    reason: 'Need to recharge/resupply',
    reason_id: 'cancellation_reason_7',
  },
  {
    reason: 'Mechanical issue',
    reason_id: 'cancellation_reason_8',
  },
  {
    reason: 'Other',
    reason_id: 'cancellation_reason_9',
  },
];

type StatusChange = Record<
  Partial<OrderStatusTypes>,
  Array<Partial<OrderStatusTypes>>
>;

const STATUS_CHANGE_MAP: StatusChange = {
  placed: ['cancelled'],
  on_the_way: ['arrived', 'cancelled'],
  arrived: ['completed', 'cancelled'],
  assigned: ['on_the_way', 'cancelled'],
  created: [],
  unpaid: [],
  in_progress: [],
  unassigned: [],
  completed: [],
  cancelled: [],
};

export class ChangeJobStatusModal extends React.Component<any, any> {
  state = {
    isLoading: false,
    selectedStatus: this.props.status,
    selectedCancellationReasonId: '',
  };

  changeOrderStatus = async () => {
    const { job_id } = this.props;

    if (!(this.state.selectedStatus && job_id)) {
      message.info('Please select a Job status');
      return;
    }

    if (this.state.selectedStatus === JobStatus.cancelled) {
      this.handleCancelRequest();
    } else {
      // Handle other change status calls here (pending BE work)
      this.handleStatusUpdate();
    }
  };

  handleStatusUpdate = async () => {
    try {
      this.setState({ isLoading: true });
      const { job_id, onUpdateHandler, closeModal, closeDrawer } = this.props;
      const { selectedStatus } = this.state;

      await updateOrderStatus(job_id, { status: selectedStatus });
      message.success(`Order ${job_id} was updated successfully.`);
      onUpdateHandler && onUpdateHandler();
      closeModal && closeModal();
      closeDrawer && closeDrawer();
    } catch (e) {
      message.error(e);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  handleCancelRequest = async () => {
    try {
      this.setState({ isLoading: true });
      const { job_id, onUpdateHandler, closeModal, closeDrawer } = this.props;
      const { selectedCancellationReasonId } = this.state;
      const foundReason = CANCEL_REASONS.find(
        (reason: CancelOrderRequest) =>
          reason.reason_id === selectedCancellationReasonId
      );

      if (!foundReason) {
        throw 'Please select a cancellation reason';
      }

      await cancelOrderWithReason(job_id, {
        reason: foundReason.reason,
        reason_id: foundReason.reason_id,
      });
      message.success(`Order ${job_id} was cancelled successfully.`);
      onUpdateHandler && onUpdateHandler();
      closeModal && closeModal();
      closeDrawer && closeDrawer();
    } catch (e) {
      message.error(e);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { isLoading } = this.state;
    const orderStatus: OrderStatusTypes = this.props.status;

    return (
      <Modal
        width={570}
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.9)' }}
        title={
          <span className={'alert-detail-view-modal-title'}>
            Change Order Status
          </span>
        }
        visible={this.props.visible}
        onCancel={this.props.closeModal}
        footer={[
          <Button
            key="back"
            className={styles.modalCancel}
            onClick={this.props.closeModal}>
            Cancel
          </Button>,
          <Button
            className={styles.modalOk}
            key="submit"
            type="primary"
            loading={isLoading}
            onClick={this.changeOrderStatus}>
            Save
          </Button>,
        ]}>
        <Select
          style={{ width: '100%' }}
          size="large"
          onSelect={value => {
            this.setState({
              selectedStatus: value,
            });
          }}
          placeholder="Order Status">
          {STATUS_CHANGE_MAP[orderStatus].map((status: string) => (
            <Option key={status} value={status}>
              {status}
            </Option>
          ))}
        </Select>
        {this.state.selectedStatus === JobStatus.cancelled && (
          <>
            <div style={{ margin: '16px 0' }}>
              <span className={'alert-detail-view-modal-item'}>Reason</span>
              <Select
                style={{ width: '100%', marginTop: '16px' }}
                size="large"
                onSelect={value => {
                  this.setState({
                    selectedCancellationReasonId: value,
                  });
                }}
                defaultValue={this.state.selectedCancellationReasonId}
                placeholder="Select the option">
                {CANCEL_REASONS.map((reasonObj: CancelOrderRequest) => (
                  <Option key={reasonObj.reason_id} value={reasonObj.reason_id}>
                    {reasonObj.reason}
                  </Option>
                ))}
              </Select>
            </div>
          </>
        )}
      </Modal>
    );
  }
}
