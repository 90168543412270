export interface ZoneFleet {
  fleet_id: number;
  fleet_name: string;
  fleet_thumb_image: string;
}

export interface Point {
  x: number;
  y: number;
}

export default interface Zone {
  area_id: string;
  fleets: Array<ZoneFleet>;
  is_active: number;
  last_updated_at: string;
  region_data: Array<Point>;
  region_description: string;
  region_id: number;
  region_name: string;
  selected_team_id: Array<number>;
  type: number;
  country_id: number;
  slot_task_count: number;
}

export interface ZonePayload {
  access_token: string;
  country_id: number;
  type: number;
  user_id: number;
}

/**
 * Filter a zone by name, description and ID
 * @param zone Zone
 * @param filter string
 */
export function filterZone(zone: Zone, filter: string): boolean {
  filter = filter.toLowerCase();
  return (
    zone.region_name.toString().toLowerCase().includes(filter) ||
    zone.region_description.toLowerCase().includes(filter) ||
    zone.region_id.toString().includes(filter)
  );
}

export interface ZoneCapInterface {
  end: { hour: number; minute: number };
  id: string;
  limit: number;
  buffer?: number;
  start: { hour: number; minute: number };
}
export interface ZoneUtilisationInterface extends ZoneCapInterface {
  utilized: number;
}
export const toZoneGeoJson = (zones: Array<Zone>) => {
  return {
    zones: {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: zones.map((zone, index) => {
          return {
            type: 'Feature',
            id: zone.region_id,
            properties: {
              percentile: index + 1,
              name: zone.region_name,
              description: zone.region_description,
              type: 'zone',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                zone.region_data.map((point) => [point.y, point.x]),
              ],
            },
          };
        }),
      },
    },
  };
};
