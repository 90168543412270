import React from 'react';
import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { Dropdown, Menu, Tag } from 'antd';
import { Pilot } from '../../models/Pilots';

const menu = (record: Pilot, handleActionClick: any) => (
  <Menu onClick={e => handleActionClick(e.key, record)}>
    <Menu.Item key="edit" icon={<EditOutlined />}>
      Edit
    </Menu.Item>
    {record.active ? (
      <Menu.Item key="inactivate" icon={<DeleteOutlined />}>
        In-Activate
      </Menu.Item>
    ) : (
      <Menu.Item key="activate" icon={<CheckCircleOutlined />}>
        Activate
      </Menu.Item>
    )}
  </Menu>
);

export const getDataTableColumns = (handleActionClick: any) => [
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'code',
    sorter: (a: any, b: any) => a.code.localeCompare(b.code),
  },
  {
    title: 'Name',
    dataIndex: 'first_name',
    key: 'full_name',
    render: (text: string, record: Pilot) => (
      <span>
        {record.first_name} {record.last_name}
      </span>
    ),
    sorter: (a: Pilot, b: Pilot) => {
      return a.first_name.localeCompare(b.first_name);
    },
  },
  {
    title: 'Phone',
    dataIndex: 'phone_number',
    key: 'phone_number',
    sorter: (a: any, b: any) => a.phone_number.localeCompare(b.phone_number),
  },
  {
    title: 'Status',
    dataIndex: 'active',
    key: 'active',
    render: (isActive: boolean, record: Pilot) => (
      <span>
        {isActive ? (
          <Tag color="#FF9900">Active</Tag>
        ) : (
          <Tag color="#E84A35">Not Active</Tag>
        )}
      </span>
    ),
  },
  {
    title: 'Action',
    key: 'operation',
    render: (text: string, record: any) => (
      <Dropdown trigger={['click']} overlay={menu(record, handleActionClick)}>
        <a
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}>
          <EllipsisOutlined style={{ fontSize: '20px' }} />
        </a>
      </Dropdown>
    ),
  },
];
