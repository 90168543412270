import { isDevMode, isProdEnv } from './auth';
export const ZONE_HEALTH_VIEW_ZOOM = 10.5;
export const NO_ORDERS_ZOOM = 9.3;
export const BASE_LEGACY_URL = isDevMode()
  ? `https://test-api.cafu.app`
  : `https://${window.location.hostname}/cafu_legacy_server`;
export const DUBAI_TIMEZONE_OFFSET = 240; // offset in minutes
export const DASHBOARD_ACCESS_POC = 'access.request@cafu.com'; // poc to be displayed on invalid access dialong on login page.
export const MIDNIGHT_ORDERS_START_TIME = '18:00'; // At late hours, past the start time, we show orders from start time to end time
export const MIDNIGHT_ORDERS_END_TIME = '02:00';
export const CALL_ME_OPTIONS = [
  'Call at any time',
  'Call at anytime',
  'Pilot should give me a call',
  'Pilot is allowed to call me',
];
export const NIGHTLY_ORDER_START_HOUR = 22;
export const NIGHTLY_ORDER_END_HOUR = 7;
export const COUNTRIES: { [key: number]: string } = {
  1: 'UAE',
};
export const LOW_INV_VALUE = 150;

export const EMIRATES_OPTIONS = [
  'Dubai',
  'Sharjah',
  'Ajman',
  'Abu Dhabi',
  'Ras al-Khaimah',
  'Umm al-Quwain',
  'Fujairah',
];
export const VEHICLE_COLORS = [
  'White',
  'Silver',
  'Grey',
  'Black',
  'Beige',
  'Yellow',
  'Orange',
  'Brown',
  'Red',
  'Green',
  'Blue',
  'Other',
];
export const REACT_APP_CAR_SERVICE_API_HOSTNAME = isDevMode()
  ? 'https://99-review-release-7d7jqz.ingress.staging.techstr1.de/car-services'
  : `https://${window.location.hostname}/car-services`;

export const GOOGLE_STATIC_MAPS_API_KEY =
  'AIzaSyAp9E8Uyk0PKY-zrKijer19cEwb0TZNFtA';

export const API_HOST = isProdEnv()
  ? 'https://api.internal.cafu.app'
  : 'https://internal-ev-bff.ca.dev.cafu.app';

export const REACT_APP_ENV = 'production';
export const REACT_APP_MENA_SERVER_API_HOSTNAME = 'https://test-api.cafu.app';
export const REACT_APP_BFF_SERVER_API_HOSTNAME =
  'https://173-review-release-7d7jqz.ingress.staging.techstr1.de/bff';
export const REACT_APP_NEW_VERTICALS_API_HOSTNAME =
  'http://localhost:5000/car-services';
export const REACT_APP_PROMOTIONS_API_HOSTNAME =
  'http://localhost:3002/promotions';
export const REACT_APP_MENA_SCHEDULER_API_HOSTNAME =
  'https://test-scheduler.cafu.app';
export const REACT_APP_API_EWD_HOSTNAME =
  'https://116-review-review-bra-yvazsk.ingress.staging.techstr1.de/early_warning_dashboard';
export const REACT_APP_B2B_ORDERS_API_HOSTNAME =
  'https://201-review-develop-3zknud.ingress.staging.techstr1.de/b2b/orders';
export const REACT_APP_B2B_CLIENTS_API_HOSTNAME =
  'https://208-review-develop-3zknud.ingress.staging.techstr1.de/b2b/clients';
export const REACT_APP_CALENDAR_API_HOSTNAME =
  'https://205-review-develop-3zknud.ingress.staging.techstr1.de';
export const REACT_APP_ALERT_BUS_API_HOSTNAME =
  'https://352-review-develop-3zknud.ingress.staging.techstr1.de/alertbus';
export const REACT_APP_MAPBOX_TOKEN =
  'pk.eyJ1IjoiZW1lbDEyMyIsImEiOiJjazk5dnduZnkwMzVjM2VwN240bXRmdDY1In0.-HHWK4FiRW4p5xprf-Eb1w';
export const REACT_APP_MAPBOX_BASE_STYLE =
  'mapbox://styles/emel123/ck9b6jijw0t2v1ipbicji6jvj';
export const REACT_APP_DEFAULT_MAP_ZOOM = '10';
export const REACT_APP_NEW_RELIC_APP_ID = 642067118;
export const REACT_APP_NEW_RELIC_AGENT_ID = 642067118;
export const REACT_APP_NEW_RELIC_LICENSE = 'NRJS-8171d0d8343b209c252';
export const REACT_APP_NEW_RELIC_ACCOUNT_ID = 2655574;
export const REACT_APP_NEW_RELIC_TRUST_KEY = 2436090;
export const REACT_APP_IS_AZURE_AD_AVAILABLE = 'false';
export const REACT_APP_ZONE_HEALTH_WS =
  'wss://uuophyopp1.execute-api.us-east-1.amazonaws.com/dev';
export const REACT_B2B_BUSINESS_API_BASE_URL =
  'https://business-review.techstr1.de/business/internal';
export const REACT_APP_VAS_BASE_URL =
  'https://us-central1-cafu-customer-application-dev.cloudfunctions.net';
export const REACT_APP_NV_SUBSCRIPTIONS =
  'https://476-review-develop-3zknud.ingress.staging.techstr1.de/nv-subscriptions';
export const REACT_APP_CAFU_BUSINESS_API_HOSTNAME = 'http://localhost:5009';
