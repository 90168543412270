import axios, { AxiosError } from 'axios';
import Cookies from 'universal-cookie';
import { message } from 'antd';
import { clearAccessCookies } from './auth';
import {
  CancelOrderRequest,
  OrdersListRequest,
  UpdateStatusRequest,
} from '../models/Order';
import { useQuery } from '@tanstack/react-query';
import { CreatePilotRequest } from '../models/Pilots';
import { API_HOST } from './app-constants';

const ERROR_MESSAGES: any = {
  'api.errors.invalid_credentials': 'Invalid Credentials',
};

/**
 * localhost points to dev URLs
 * Prod env potins to hostname as base path and proxied at nginx.
 */
export const apiEndpoint = `${API_HOST}/api/v1`;

const cookies = new Cookies();
const GENERIC_ERROR_MESSAGE = 'Something went wrong, please try again';

const apiInstance = axios.create({
  baseURL: apiEndpoint,
});

const getHeaders = (options: any) => {
  const { customHeaders = {}, useAuthHeader = true } = options;
  const headers: any = {
    'Accept-Language': 'en-US',
  };

  const token = cookies.get('token');

  if (token && useAuthHeader) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  return Object.assign({}, headers, customHeaders);
};

const handleError = async (error: AxiosError) => {
  const statusCode = error?.response?.status;
  const errorCode = error?.response?.data?.error_code;

  if (statusCode !== 401 && statusCode !== 403) {
    message.destroy();
    message.error(
      ERROR_MESSAGES[error?.response?.data?.message] ||
        error?.response?.data?.message ||
        GENERIC_ERROR_MESSAGE
    );
  }

  if (statusCode === 401 || errorCode === 'unauthorized') {
    clearAccessCookies();
    window.location.reload();
  }

  throw error;
};

const request = (
  method: any,
  endpoint: string,
  options: any,
  payload?: any
) => {
  const headers = getHeaders(options);

  if (method === 'get') {
    return apiInstance
      .get(endpoint, { headers, params: payload })
      .catch((error: AxiosError) => handleError(error));
  }

  if (method === 'post') {
    return apiInstance
      .post(endpoint, payload, { headers })
      .catch((error: AxiosError) => handleError(error));
  }

  if (method === 'patch') {
    return apiInstance
      .patch(endpoint, payload, { headers })
      .catch((error: AxiosError) => handleError(error));
  }
};

export const login = ({ username, password }: any) =>
  request(
    'post',
    '/auth',
    { useAuthHeader: false },
    { grant_type: 'password', username, password }
  );

export const logout = () =>
  request(
    'post',
    '/logout',
    {},
    {
      refresh_token: cookies.get('refresh_token'),
    }
  );

export const getOrdersList = (query: OrdersListRequest | undefined = {}) =>
  request('get', '/orders', {}, query);

export const useGetOrdersList = (query: OrdersListRequest | undefined = {}) => {
  return useQuery({
    queryKey: ['orders'],
    refetchInterval: 60000,
    queryFn: async () => {
      const response = await getOrdersList(query);
      return response?.data?.data ?? [];
    },
  });
};

export const getOrderDetails = (id: number) =>
  request('get', `/orders/${id}`, {}, {});

export const cancelOrderWithReason = (
  id: number,
  payload: CancelOrderRequest
) => request('patch', `/orders/${id}/cancel`, {}, payload);

export const updateOrderStatus = (id: number, payload: UpdateStatusRequest) =>
  request('patch', `/orders/${id}/status`, {}, payload);

export const getPilotsList = () => request('get', '/pilots', {}, {});

export const createPilot = (payload: CreatePilotRequest) =>
  request('post', '/pilots', {}, payload);

export const editPilot = (id: number, payload: CreatePilotRequest) =>
  request('patch', `/pilots/${id}`, {}, payload);

export const getAssetsList = () => request('get', '/assets', {}, {});

export const useGetAssetsList = () => {
  return useQuery({
    queryKey: ['assets'],
    refetchInterval: 60000,
    queryFn: async () => {
      const response = await getAssetsList();
      return response?.data?.data ?? [];
    },
  });
};

export const getOrderReceipt = (id: number) =>
  request('get', `/orders/${id}/receipt`, {}, {});
