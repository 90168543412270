import React from 'react';
import { Redirect } from 'react-router-dom';
import './fonts/FSTruman.otf';
import './css/Dashboard.css';
import 'antd/dist/antd.css';
import { isLoggedIn } from './helpers/auth';

const App: React.FC = () => {
  return <Redirect to={isLoggedIn() ? '/home' : '/loginwithusername'} />;
};

export default App;
