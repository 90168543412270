import { LayerProps } from 'react-map-gl';

const truckText = {
  layout: {
    'text-optional': true,
    'text-field': [
      'step',
      ['zoom'],
      '',
      12,
      ['get', 'license'],
      16,
      ['concat', ['get', 'license'], '\n', ['get', 'name']],
    ],
    'text-size': {
      base: 0.9,
      stops: [
        [5, 10],
        [12, 18],
      ],
    },
    'text-justify': [
      'case',
      ['boolean', ['get', 'left'], false],
      'right',
      'left',
    ],
    'text-anchor': [
      'case',
      ['boolean', ['get', 'left'], false],
      'top-right',
      'top-left',
    ],
    'text-offset': [
      'case',
      ['boolean', ['get', 'left'], false],
      ['literal', [-1, -0.5]],
      ['literal', [1, -0.5]],
    ],
    'text-padding': 0.5,
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
  },
  paint: {
    'text-color': '#73A6B7',
    'text-halo-blur': 0,
    'text-halo-color': '#212121',
    'text-halo-width': 1,
    'text-opacity': 1,
  },
};

export const trucksLayer: LayerProps = {
  id: 'pilots',
  type: 'symbol',
  source: 'pilots',
  paint: {
    ...truckText.paint,
  },
  layout: {
    'icon-image': [
      'coalesce',
      [
        'image',
        [
          'case',
          ['==', ['get', 'fleet_alert'], true],
          'pulsing-alert',

          ['==', ['get', 'fleet_status_color'], 'low-charge-truck'],
          'low-charge-truck',

          ['==', ['get', 'fleet_status_color'], 'high-charge-truck'],
          'high-charge-truck',

          'pulsing-dot',
        ],
      ],
      ['image', 'rocket-15'],
    ],
    visibility: 'visible',
    'icon-allow-overlap': true,
    'icon-size': ['step', ['zoom'], 0.8, 10, 0.8, 14, 1.0, 16, 1.2],
    ...truckText.layout,
  },
};
